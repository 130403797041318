import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DialogForm from "../../shared/components/DialogForm";
import AddRoomRate from "./AddRoomRate";
import { connect, useSelector } from "react-redux";
import General from "./General";
import Roomrates from "./Roomrates";
import OverView from "./OverView";
import CancellationPolicy from "./CancellationPolicy";
import CleaningFees from "./CleaningFees";
import AddVirtualRates from "./AddVirtualRates";
import AddCancellation from "./AddCancellation";
import EditCancellation from "./EditCancellation";
import { Routing } from "../../shared/constants/routing";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SeasonalPricingList from "./SeasonalPricing.js/SeasonalPricingList";
import BookingRestrictionList from "./BookingRestrictions.js/BookingRestrictionList";
import DiscountedRate from "./discountRates/DiscountedRate";
import { useTranslation } from "react-i18next";
import {
  getPermission,
  getPermissionList,
  getRateByDayOverview,
} from "../../utils/helper";
import NotExist from "../About/NotExist";
import { fetchList } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import moment from "moment";
import { toast } from "react-toastify";
import {
  roomCategoryTypesFailed,
  roomCategoryTypesSuccess,
} from "../../Reducer/roomRateSlice";
import OtaOverview from "./OtaOverview";
import CompetitorHotels from "./CompetitorHotels";
import AddCompetitorHotelDialog from "./AddDialog/AddCompetitorHotelDialog";
import { overViewCalenderChange } from "../../Reducer/FilterSlice";
import AvailabilityOta from "./AvailabilityOta";
import OtaLogs from "./OtaLogs";
import Loader from "../../layout/Loader";
// import { roomCategoryTypesFailed, roomCategoryTypesSuccess } from "../../Reducer/roomRateSlice";

const RoomRatesView = (props) => {
  const navigate = useNavigate();

  const {
    calenderViewChange,
    calenderView,
    initialDate,
    perMonth,
    roomCategoryTypes,
    roomCategoryTypesSuccess,
    roomCategoryTypesFailed,
  } = props;
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // console.log(calenderView, 34);

  const [viewPermission, setViewPermission] = useState(null);
  const auth = useSelector((state) => state.auth);

  const getPermissions = async () => {
    const ViewPermission = await getPermission("Room rates", 2);
    setViewPermission(ViewPermission);
    setInitLoading(false);
  };
  useEffect(() => {
    if (Object.keys(auth?.permission)?.length > 0) {
      getPermissions();
    }
  }, [auth]);

  const [initLoading, setInitLoading] = useState(true);
  const tabRefs = useRef({});
  const containerRef = useRef(null);

  useEffect(() => {
    if (!initLoading && viewPermission?.hasPermission !== null) {
      const tabParam = searchParams.get("tab") || 0;
      const defaultTab = tabParam !== 0 ? tabParam : "overview";
      console.log(tabParam, 79);
      const scrollToTab = () => {
        if (tabRefs.current[defaultTab]) {
          handletabs(defaultTab);
        } else {
          // Retry after a short delay if refs are not ready
          setTimeout(scrollToTab, 300);
        }
      };
      scrollToTab();
      // if (searchParams.get("tab")) {
      //   handletabs(searchParams.get("tab"));
      // } else {
      //   handletabs("overview");
      // }
    }
  }, [searchParams, initLoading]);

  const [alignment, setAlignment] = React.useState("left");
  const { t, i18n } = useTranslation();
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const getCurrentState = () => {
    return props.state;
  };

  const [checked, setChecked] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRestriction, setOpenRestriction] = React.useState(false);
  const [openSeasonPricing, setSeasonPricing] = React.useState(false);
  const [openVirtualRates, setVirtualRates] = React.useState(false);
  const [openAddPolicy, setAddPolicy] = React.useState(false);
  const [openEditPolicy, setEditPolicy] = React.useState(false);

  const [tabName, setTabName] = React.useState("overview");
  const [loading, setLoading] = useState(false);
  const [eventss, setreservationEventss] = useState([]);
  const [arg, setArg] = useState({});
  const [resourcess, setResourcess] = useState([]);
  const [roomRateId, setRoomRateId] = useState(0);
  const [roomRateCate, setRoomRateCate] = useState([]);
  // const [perMonth, setPerMonth] = useState(7);
  const [openAddHotelDialog, setOpenAddHotelDialog] = useState(false);

  const [hotelId, setHotelId] = useState(null);

  // console.log(roomRateCate, 164);

  useEffect(() => {
    if (Object.keys(auth?.permission)?.length > 0) {
      getPermissions();
    }
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setHotelId(items.hotel_id);
    }
  }, [auth]);

  function getDates(
    startDate,
    stopDate,
    id,
    rateDetail,
    sessionId,
    i,
    type,
    type_id,
    data
  ) {
    var dateArray = [];
    var currentDate = startDate;
    var stopDate = stopDate;
    // console.log(currentDate, stopDate, 229)
    while (currentDate <= stopDate) {
      let newRateDetail = [];
      let newSessionId = sessionId;

      if (
        rateDetail?.filter(
          (item) =>
            moment(item?.date).format("YYYY-MM-DD") ===
            moment(currentDate).format("YYYY-MM-DD")
        )?.length === 0
      ) {
        let anotherRateDetail = [];
        let anotherSessionList = [];

        data?.forEach((item) => {
          let flterRateDetail = item?.dateWiseRateDetail?.filter(
            (fItem) =>
              fItem?.accomodation_id === 1 && fItem?.category_id === type_id
          );

          if (
            flterRateDetail?.filter(
              (item) =>
                moment(item?.date).format("YYYY-MM-DD") ===
                moment(currentDate).format("YYYY-MM-DD")
            )?.length > 0
          ) {
            anotherRateDetail?.push(flterRateDetail);
            anotherSessionList?.push(item?.id);
          }
        });
        if (anotherRateDetail?.length > 0) {
          newRateDetail = anotherRateDetail[0];
          newSessionId = anotherSessionList[0];
        }
      }

      dateArray.push({
        resourceId: id + "A" + type + i,
        // typeId: type_id,
        start: moment(currentDate).format("YYYY-MM-DD"),
        title:
          rateDetail?.filter(
            (item) =>
              moment(item?.date).format("YYYY-MM-DD") ===
              moment(currentDate).format("YYYY-MM-DD")
          )?.length > 0
            ? (rateDetail?.filter(
                (item) =>
                  moment(item?.date).format("YYYY-MM-DD") ===
                  moment(currentDate).format("YYYY-MM-DD")
              ))[0]?.rate
            : newRateDetail?.length > 0
            ? (newRateDetail?.filter(
                (item) =>
                  moment(item?.date).format("YYYY-MM-DD") ===
                  moment(currentDate).format("YYYY-MM-DD")
              ))[0]?.rate
            : "",
        //  getRateByDay(details, startDate),
        textColor: "black",
        type: "rate",
        category_id: id,
        // sessionId: sessionId,
        sessionId: newSessionId,
        color: "transparent",
      });
      currentDate = moment(currentDate).add(1, "days");
    }

    return dateArray;
  }

  const handleChangeRoomRate = (e) => {
    setRoomRateId(e.target?.value);
  };

  const [bookingData, setBookingData] = useState({
    suggestedResultData: [],
    result: {},
    resultAvail: {},
    resultMinStay: {},
  });

  const handleGetResult = async (arg) => {
    const result = await fetchList(
      ApiEndPoints.GET_RATE_FOR_BOOKING_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    );
    setBookingData((prev) => ({ ...prev, result: result }));
    if (result?.status === 200) {
      let rateId = roomRateId;

      if (
        (roomRateId === 0 ||
          !result?.data
            ?.map((item) => item?.category_rate_type_id)
            .includes(rateId)) &&
        result?.data?.length > 0
      ) {
        setRoomRateId(result?.data[0]?.category_rate_type_id);
        // rateId = result?.data[0]?.category_rate_type_id;
      }
      setRoomRateCate(result?.data);
    } else {
      setRoomRateCate([]);
    }
  };

  const handleGetResultAvail = async (arg) => {
    const resultAvail = await fetchList(
      ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    );
    setBookingData((prev) => ({ ...prev, resultAvail: resultAvail }));
  };

  const handleGetMinStay = async (arg) => {
    const resultMinStay = await fetchList(
      ApiEndPoints.GET_MIN_STAY_BY_DATE +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format(
          "YYYY-MM-DD"
        )}&hotel_id=${hotelId}`
    );
    setBookingData((prev) => ({ ...prev, resultMinStay: resultMinStay }));
  };

  const handleGetSuggestedResult = async (arg) => {
    setLoading(true);
    const suggestedResult = await fetchList(
      ApiEndPoints.GET_SUGGESTED_RATE_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[0]}`
    );
    if (suggestedResult?.status === 200) {
      setBookingData((prev) => ({
        ...prev,
        suggestedResultData: suggestedResult?.data,
      }));
      // setLoading(false);
    } else {
      setBookingData((prev) => ({
        ...prev,
        suggestedResultData: [],
      }));
      // setLoading(false);
    }
  };

  const handleGetAllBookingRate = (arg) => {
    setLoading(true);
    getRoomData();
    handleGetResult(arg);
    handleGetResultAvail(arg);
    handleGetMinStay(arg);
    handleGetSuggestedResult(arg);
  };

  const handleGetAllBookingRateData = async (arg) => {
    setLoading(true);

    let dateArray = [];
    const result = bookingData?.result;
    // await fetchList(
    //   ApiEndPoints.GET_RATE_FOR_BOOKING_OVERVIEW +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    // );
    const resultAvail = bookingData?.resultAvail;
    // await fetchList(
    //   ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    // );

    const resultMinStay = bookingData?.resultMinStay;
    //  await fetchList(
    //   ApiEndPoints.GET_MIN_STAY_BY_DATE +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&hotel_id=${hotelId}`
    // );

    const suggestedResultData = bookingData?.suggestedResultData;

    if (result?.status === 200) {
      let rateId = roomRateId;
      // console.log(rateId, result?.data, 280);
      // setRoomRateCate(result?.data);
      // if ((rateId === 0 || !result?.data?.map(item=> item?.category_rate_type_id).includes(rateId)) && result?.data?.length > 0) {
      //   setRoomRateId(result?.data[0]?.category_rate_type_id);
      //   rateId = result?.data[0]?.category_rate_type_id;
      // }
      const dates = result?.data
        ?.find((ele) => +ele?.category_rate_type_id === +rateId)
        ?.dateWiseRateDetail?.map((item) => new Date(item?.date));
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));

      // Generate all dates in the range
      const allDates = [];
      for (
        let d = new Date(minDate);
        d <= maxDate;
        d.setDate(d.getDate() + 1)
      ) {
        allDates.push(new Date(d).toISOString().split("T")[0]);
      }

      allDates?.forEach((date) => {
        for (let i in roomCategoryTypes) {
          // let roomCateCo = roomCategoryTypes?.find(
          //   (sitem) => roomCategoryTypes[+i]?.id === item?.category_id
          // );
          let roomCateCo = suggestedResultData?.find(
            (sItem) =>
              moment(sItem?.date).format("YYYY-MM-DD") === date &&
              roomCategoryTypes[+i]?.id === sItem?.category_id
          );

          if (
            roomCategoryTypes[+i]?.id === roomCateCo?.category_id &&
            roomCateCo
          ) {
            // console.log(roomCategoryTypes[+i], i, item,roomCategoryTypes[+i]?.id,item?.category_id,  342);
            dateArray.push({
              resourceId:
                roomCateCo?.category_id +
                "B" +
                1000 +
                roomCategoryTypes[+i]?.type +
                i,
              category_id: roomCateCo?.category_id,
              start: moment(roomCateCo?.date).format("YYYY-MM-DD"),
              title: roomCateCo?.rate,
              textColor: "black",
              sessionId: roomCateCo?.id,
              sugId: roomCateCo?.id,
              color: "transparent",
              type: "suggestedRate",
              // type: item?.type,
              categoryId: roomCategoryTypes?.filter(
                (el) => el?.id === roomCateCo?.category_id
              )[0]?.type,
              // rate_plan_id: item?.rate_plan_id,
              // className: "color-blue"
            });
            // break;
          } else {
            dateArray.push({
              resourceId:
                roomCategoryTypes[+i]?.id +
                "B" +
                1000 +
                roomCategoryTypes[+i]?.type +
                i,
              category_id: roomCategoryTypes[+i]?.id,
              start: moment(date).format("YYYY-MM-DD"),
              title: "-",
              textColor: "black",
              sessionId: null,
              sugId: null,
              color: "transparent",
              type: "suggestedRate",
              // type: item?.type,
              categoryId: roomCategoryTypes?.filter(
                (el) => el?.id === roomCategoryTypes[+i]?.id
              )[0]?.type,
              // rate_plan_id: item?.rate_plan_id,
              // className: "color-blue"
            });
          }
        }
      });

      result?.data
        ?.filter((ele) => +ele?.category_rate_type_id === +rateId)
        ?.map((item) => {
          for (let i in roomCategoryTypes) {
            let rateDetail = item?.dateWiseRateDetail?.filter(
              (fItem) =>
                fItem?.accomodation_id === 1 &&
                fItem?.category_id === roomCategoryTypes[i]?.type_id
            );
            let startDate = moment(arg?.startStr);
            let stopDate = moment(arg?.endStr);
            // let startDate = moment(item?.start_date);
            // let stopDate = moment(item?.end_date);
            let sessionId = item?.id;

            dateArray = dateArray.concat(
              getDates(
                startDate,
                stopDate,
                roomCategoryTypes[i]?.id,
                rateDetail,
                sessionId,
                i,
                roomCategoryTypes[i]?.type,
                roomCategoryTypes[i]?.type_id,
                result?.data
              )
            );

            // console.log(dateArray, 208);
            if (resultAvail?.status === 200) {
              resultAvail?.data?.forEach((item) => {
                let roomCateCo = roomCategoryTypes?.filter(
                  (sitem) => sitem?.id === item?.catId
                );
                let filterMulti = dateArray?.filter(
                  (fItem) =>
                    fItem?.resourceId ===
                      item?.catId +
                        "C" +
                        // * (+i + 1)
                        10000 +
                        roomCategoryTypes?.filter(
                          (sitem) => sitem?.id === item?.catId
                        )[0]?.type +
                        i &&
                    fItem?.start === moment(item?.date).format("YYYY-MM-DD")
                );
                if (roomCateCo?.length > 0 && filterMulti?.length === 0) {
                  dateArray.push({
                    resourceId:
                      item?.catId +
                      "C" +
                      // * (+i + 1)
                      10000 +
                      roomCategoryTypes?.filter(
                        (sitem) => sitem?.id === item?.catId
                      )[0]?.type +
                      i,
                    // typeId: type_id,
                    start: moment(item?.date).format("YYYY-MM-DD"),
                    title: item?.availableRooms + "/" + item?.totalRoomOfCat,
                    //  getRateByDay(details, startDate),
                    textColor: "black",
                    sessionId: item?.catId,
                    color: "transparent",
                    type: "availability",
                  });
                }
              });
            }
          }
        });

      if (resultMinStay?.status === 200) {
        let currentDate = moment(arg?.startStr).format("YYYY-MM-DD");
        let lastDate = moment(arg?.endStr).format("YYYY-MM-DD");

        while (
          moment(currentDate, "YYYY-MM-DD").isSameOrBefore(
            lastDate,
            "YYYY-MM-DD"
          )
        ) {
          let minStay = 1;
          // console.log(currentDate,250)
          resultMinStay?.data?.forEach((item, ind) => {
            if (
              moment(currentDate, "YYYY-MM-DD").isSameOrAfter(
                item?.start_date,
                "YYYY-MM-DD"
              ) &&
              moment(currentDate, "YYYY-MM-DD").isSameOrBefore(
                item?.end_date,
                "YYYY-MM-DD"
              )
            ) {
              // console.log(item?.min_stay_length,currentDate, 259);
              minStay = item?.min_stay_length;
            }
          });
          roomCategoryTypes?.forEach((element, index) => {
            dateArray.push({
              resourceId: element?.id + "D" + 10000000 + element?.type + index,
              start: currentDate,
              title: minStay,
              textColor: "black",
              sessionId: element?.id,
              color: "transparent",
              type: "availability",
            });
          });
          currentDate = moment(currentDate, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD");
        }
      }
      let uniqueDataArray = dateArray.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) => t.resourceId === item.resourceId && t.start === item.start
          )
      );
      setreservationEventss(
        uniqueDataArray.filter((item) => item?.title !== "")
      );
      // console.log(dateArray.filter((item) => item?.title !== ""), 206);
      setLoading(false);
    } else {
      setreservationEventss([]);
      setLoading(false);
    }
  };

  const getRoomData = async () => {
    // setLoading(true)
    const resultRe = await fetchList(ApiEndPoints.ROOM_CATEGORY_SELECT_LIST);
    const RoomData = [];
    if (resultRe?.status === 200) {
      for (var j in resultRe.data) {
        RoomData.push({
          id: resultRe.data[j].id,
          name: resultRe.data[j].roomcategory_name,
          type: resultRe.data[j]?.room_type_short_code,
          type_id: resultRe.data[j].id,
          max_occupancy: resultRe.data[j]?.max_occupancy,
          min_rate: +resultRe.data[j]?.min_rate,
          max_rate: +resultRe.data[j]?.max_rate,
          // housekeeping: resultRe.data[j]?.houseKeepingStatus?.status,
        });
      }
      if (!(JSON.stringify(RoomData) === JSON.stringify(roomCategoryTypes))) {
        roomCategoryTypesSuccess(RoomData);
      }
    } else {
      roomCategoryTypesFailed();
    }
  };

  // let ignore;
  // useEffect(() => {
  //   if (!ignore) {
  //     getRoomData();
  //   }
  //   return () => {
  //     ignore = true;
  //   };
  // }, []);

  const [ignoreTest, setIgnoreTest] = useState(false);

  useEffect(() => {
    // let ignore = false;
    if (
      // roomCategoryTypes?.length > 0 &&
      hotelId &&
      // ignoreTest &&
      arg?.startStr != arg?.endStr
    ) {
      handleGetAllBookingRate(arg);
      setIgnoreTest(false);
    }
    return () => {
      setIgnoreTest(true);
    };
  }, [arg, hotelId]);

  useEffect(() => {
    if (roomCategoryTypes?.length > 0) {
      handleGetAllBookingRateData(arg);
    }
  }, [bookingData, roomCategoryTypes, roomRateId]);

  // console.log(bookingData, 461);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const handleOpenRestrictionDialog = () => setOpenRestriction(true);
  const handleCloseRestrictionDialog = () => setOpenRestriction(false);
  const handleOpenSeasonPricingDialog = () => setSeasonPricing(true);
  const handleCloseSeasonPricingDialog = () => setSeasonPricing(false);
  const handleOpenVirtualRatesDialog = () => setVirtualRates(true);
  const handleCloseVirtualRatesDialog = () => setVirtualRates(false);
  const handleOpenAddPolicyDialog = () => setAddPolicy(true);
  const handleCloseAddPolicyDialog = () => setAddPolicy(false);
  const handleOpenEditPolicyDialog = () => setEditPolicy(true);
  const handleCloseEditPolicyDialog = () => setEditPolicy(false);

  const handletabs = (e) => {
    console.log(e, 627);
    document.getElementById("overview").classList.remove("active-tab");
    // document.getElementById("general").classList.remove("active-tab");
    document.getElementById("roomrates").classList.remove("active-tab");
    // document.getElementById("virtualrates").classList.remove("active-tab");
    document.getElementById("cancellation").classList.remove("active-tab");
    // document.getElementById("meals").classList.remove("active-tab");
    // document.getElementById("cleaningfees").classList.remove("active-tab");
    document.getElementById("ota-overview").classList.remove("active-tab");
    document.getElementById("competitor-hotels").classList.remove("active-tab");
    document.getElementById("pricing").classList.remove("active-tab");
    document.getElementById("ota-logs").classList.remove("active-tab");
    // document.getElementById("restriction").classList.remove("active-tab");
    document
      .getElementById("availability-discrepancy")
      .classList.remove("active-tab");
    // document.getElementById("citytax").classList.remove("active-tab");
    document
      .getElementById("discountedRatePlan")
      .classList.remove("active-tab");
    document.getElementById(e).classList.add("active-tab");
    setTabName(e);
    setSearchParams({ tab: e });

    const container = containerRef.current;
    const selectedTab = tabRefs.current[e];

    if (container && selectedTab) {
      const containerRect = container.getBoundingClientRect();
      const tabRect = selectedTab.getBoundingClientRect();

      const offset = tabRect.left - containerRect.left;
      const scrollTo =
        container.scrollLeft +
        offset -
        containerRect.width / 2 +
        tabRect.width / 2;

      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let reso = [];
    // console.log(230, reso);
    roomCategoryTypes?.forEach((element, index) => {
      reso.push({
        id: element?.id + "A" + element?.type + index,
        type: element?.type,
        rateType: "Rate",
      });
      reso.push({
        id: element?.id + "B" + 1000 + element?.type + index,
        type: element?.type,
        rateType: "Suggested Rate",
      });
      reso.push({
        id:
          element?.id +
          "C" +
          // * (index + 1)
          10000 +
          element?.type +
          index,
        type: element?.type,
        rateType: "Availability",
      });
      reso.push({
        id:
          element?.id +
          "D" +
          // * (index + 1)
          10000000 +
          element?.type +
          index,
        type: element?.type,
        rateType: "Min Stay",
      });
    });

    setResourcess(reso);

    // console.log(reso, 335);
  }, [roomCategoryTypes]);

  // console.log(tabName, 160);
  const tabLabels = {
    overview: "Overview",
    "ota-overview": "OTA Overview",
    "competitor-hotels": "Competitor Hotels",
    "availability-discrepancy": "Availability Discrepancy",
    "ota-logs": "Ota Logs",
    roomrates: "Room rates",
    discountedRatePlan: "Discounted Rate",
    pricing: "Seasonal Rates",
    cancellation: "Cancellation",
  };

  const getTabLabel = (tab) => tabLabels[tab] || "Overview";

  // console.log(getTabLabel(tabName), 756);

  return (
    <>
      {viewPermission?.hasPermission !== null && !initLoading ? (
        <div className="main-layout-background h-auto">
          <div className="d-flex mt-2rem justify-content-between flex-wrap gap-2">
            <div className="d-flex align-items-center">
              <h1 className="pagename-heading">{t("Room Rate")}</h1>
              <p className="">
                {" "}
                {t("Home")} / {t(tabName)}
              </p>
            </div>
            <div className="pr-2">
              {getPermissionList("Competitor Hotels", 1)?.hasPermission &&
                tabName == "competitor-hotels" && (
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="comman-button"
                    color="primary"
                    onClick={() => setOpenAddHotelDialog(true)}
                  >
                    {t("Add Competitor Hotel")}
                  </Button>
                )}
              {tabName == "roomrates" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  className="comman-button"
                  color="primary"
                  onClick={() => navigate(Routing.CreateRoomRates)}
                >
                  {t("Add Room Rate")}
                </Button>
              )}
              {tabName == "discountedRatePlan" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  // type="submit"
                  className="comman-button"
                  color="primary"
                  onClick={() => navigate(`/discounted-rate/new`)}
                >
                  {t("Add Discount Rate")}
                </Button>
              )}
              {tabName == "restriction" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  className="comman-button"
                  color="primary"
                  onClick={() => navigate(Routing.CreateBookingRestriction)}
                >
                  {t("Add Restriction")}
                </Button>
              )}
              {tabName == "pricing" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  // type="submit"
                  className="comman-button"
                  color="primary"
                  onClick={() => navigate(Routing.CreateSeasonalRates)}
                >
                  {t("Add Season")}
                </Button>
              )}
              {tabName == "virtualrates" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  // type="submit"
                  className="comman-button"
                  color="primary"
                  onClick={() => handleOpenVirtualRatesDialog()}
                >
                  {t("Add Virtual Rates")}
                </Button>
              )}
              {getPermissionList("Cancellation", 1)?.hasPermission &&
                tabName == "cancellation" && (
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    // type="submit"
                    className="comman-button"
                    color="primary"
                    onClick={() => handleOpenAddPolicyDialog()}
                  >
                    {t("Add Cancellation Policy")}
                  </Button>
                )}
            </div>
          </div>

          <div ref={containerRef} className="nav-header  justify-content-start">
            <div
              id="overview"
              ref={(el) => (tabRefs.current["overview"] = el)}
              onClick={() => handletabs("overview")}
              className={`nav-tab h-25p text-16 active-tab`}
            >
              {t("Overview")}
            </div>
            <div
              id="ota-overview"
              ref={(el) => (tabRefs.current["ota-overview"] = el)}
              onClick={() => handletabs("ota-overview")}
              className={`nav-tab h-25p text-16`}
            >
              {t("OTA Overview")}
            </div>
            <div
              ref={(el) => (tabRefs.current["competitor-hotels"] = el)}
              id="competitor-hotels"
              onClick={() => handletabs("competitor-hotels")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Competitor Hotels")}
            </div>
            <div
              ref={(el) => (tabRefs.current["availability-discrepancy"] = el)}
              id="availability-discrepancy"
              onClick={() => handletabs("availability-discrepancy")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Availability Discrepancy")}
            </div>
            <div
              ref={(el) => (tabRefs.current["ota-logs"] = el)}
              id="ota-logs"
              onClick={() => handletabs("ota-logs")}
              className={`nav-tab h-25p text-16`}
            >
              {t("OTA Logs")}
            </div>
            {/* <div
              id="general"
              onClick={() => handletabs("general")}
              className={`nav-tab h-25p text-16`}
            >
              {t("General")}
            </div> */}
            <div
              ref={(el) => (tabRefs.current["roomrates"] = el)}
              id="roomrates"
              onClick={() => handletabs("roomrates")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Room Rates")}
            </div>
            <div
              ref={(el) => (tabRefs.current["discountedRatePlan"] = el)}
              id="discountedRatePlan"
              onClick={() => handletabs("discountedRatePlan")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Discounted Rates")}
            </div>
            <div
              ref={(el) => (tabRefs.current["pricing"] = el)}
              id="pricing"
              onClick={() => handletabs("pricing")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Seasonal pricing")}
            </div>
            {/* <div
          id="virtualrates"
          onClick={() => handletabs("virtualrates")}
          className={`nav-tab h-25p text-16`}
        >
          Virtual rates
        </div> */}
            <div
              ref={(el) => (tabRefs.current["cancellation"] = el)}
              id="cancellation"
              onClick={() => handletabs("cancellation")}
              className={`nav-tab h-25p text-16`}
            >
              {/* Cancellations & Prepayments */}
              {t("Cancellations")}
            </div>
            {/* <div
          id="meals"
          onClick={() => handletabs("meals")}
          className={`nav-tab h-25p text-16`}
        >
          Meals
        </div> */}
            {/* <div
              id="cleaningfees"
              onClick={() => handletabs("cleaningfees")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Cleaning Fees")}
            </div> */}
            {/* <div
          id="earlycheckin"
          onClick={() => handletabs("earlycheckin")}
          className={`nav-tab h-25p text-16`}
        >
          Early check-in/Late check-out
        </div> */}

            {/* <div
              id="restriction"
              onClick={() => handletabs("restriction")}
              className={`nav-tab h-25p text-16`}
            >
              {t("Booking Restrictions")}
            </div> */}

            {/* <div
              id="citytax"
              onClick={() => handletabs("citytax")}
              className={`nav-tab h-25p text-16`}
            >
              {t("City Tax")}
            </div> */}
          </div>
          {/* <div className="nav-header h-61p mt-1 border-bottom-0"></div> */}

          {getPermissionList(getTabLabel(tabName), 2)?.hasPermission && (
            <div className="mt-3 pl-md-2 pr-md-2">
              {tabName == "overview" && (
                <OverView
                  arg={arg}
                  setArg={setArg}
                  resourcess={resourcess}
                  loading={loading}
                  setLoading={setLoading}
                  viewPermission={viewPermission}
                  eventss={eventss}
                  setreservationEventss={setreservationEventss}
                  handleGetAllBookingRate={handleGetAllBookingRate}
                  perMonth={perMonth}
                  calenderViewChange={calenderViewChange}
                  calenderView={calenderView}
                  initialDate={initialDate}
                  handleChangeRoomRate={handleChangeRoomRate}
                  roomRateCate={roomRateCate}
                  roomRateId={roomRateId}
                />
              )}
              {tabName === "ota-overview" && <OtaOverview />}
              {tabName === "competitor-hotels" && <CompetitorHotels />}
              {tabName === "general" && <General />}
              {tabName === "roomrates" && <Roomrates />}
              {tabName === "discountedRatePlan" && <DiscountedRate />}
              {/* {tabName == "virtualrates" && <VirtualRates />} */}
              {tabName === "cancellation" && <CancellationPolicy />}
              {/* {tabName == "meals" && <Meals />} */}
              {tabName === "cleaningfees" && <CleaningFees />}
              {/* {tabName == "earlycheckin" && <EarlyCheckinOut />} */}
              {tabName === "pricing" && <SeasonalPricingList />}
              {tabName === "restriction" && <BookingRestrictionList />}
              {tabName === "availability-discrepancy" && <AvailabilityOta />}
              {tabName === "ota-logs" && <OtaLogs />}
            </div>
          )}
          {!getPermissionList(getTabLabel(tabName), 2)?.hasPermission && (
            <NotExist />
          )}

          <DialogForm
            scroll="paper"
            maxWidth="xl"
            title="Create room rate"
            openDialog={openDialog}
            handleDialogClose={handleDialogClose}
            bodyContent={<AddRoomRate handleDialogClose={handleDialogClose} />}
          />
          <AddCompetitorHotelDialog
            openAddHotelDialog={openAddHotelDialog}
            setOpenAddHotelDialog={setOpenAddHotelDialog}
          />
          {/* <DialogForm
        scroll="paper"
        maxWidth="lg"
        title="Add Restriction"
        openDialog={openRestriction}
        handleDialogClose={handleCloseRestrictionDialog}
        bodyContent={
          <AddRestriction handleCloseDialog={handleCloseRestrictionDialog} />
        }
      /> */}
          {/* <DialogForm
        scroll="paper"
        maxWidth="lg"
        title="Add Season"
        openDialog={openSeasonPricing}
        handleDialogClose={handleCloseSeasonPricingDialog}
        bodyContent={
          <AddSeasonPricing
            handleCloseDialog={handleCloseSeasonPricingDialog}
          />
        }
      /> */}
          <DialogForm
            scroll="paper"
            maxWidth="lg"
            title={t("Add Virtual Rates")}
            openDialog={openVirtualRates}
            handleDialogClose={handleCloseVirtualRatesDialog}
            bodyContent={
              <AddVirtualRates
                handleCloseDialog={handleCloseVirtualRatesDialog}
              />
            }
          />
          <DialogForm
            scroll="paper"
            maxWidth="lg"
            title={t("Add Cancellation policy")}
            openDialog={openAddPolicy}
            handleDialogClose={handleCloseAddPolicyDialog}
            bodyContent={
              <AddCancellation handleCloseDialog={handleCloseAddPolicyDialog} />
            }
          />
          <DialogForm
            scroll="paper"
            maxWidth="lg"
            title={t("Edit prepayment & cancellation policy")}
            openDialog={openEditPolicy}
            handleDialogClose={handleCloseEditPolicyDialog}
            bodyContent={
              <EditCancellation
                handleCloseDialog={handleCloseEditPolicyDialog}
              />
            }
          />
        </div>
      ) : initLoading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          <NotExist />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    roomCategoryTypes: state.roomRate.roomCategoryTypes,
    calenderView: state?.filters?.overViewCalender,
    initialDate: state?.filters?.overViewCalender?.initialDate,
    perMonth: state?.filters?.overViewCalender?.perMonth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomCategoryTypesSuccess: (data) =>
      dispatch(roomCategoryTypesSuccess(data)),
    roomCategoryTypesFailed: () => dispatch(roomCategoryTypesFailed()),
    calenderViewChange: (data) => dispatch(overViewCalenderChange(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RoomRatesView);
