import { Button, Grid, Typography } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShowInputError from "../../../shared/components/ShowInputError";
import { Routing } from "../../../shared/constants/routing";
import FormikInput1 from "../../../shared/material-ui-formik/FormikInput1";
import FormikSelect from "../../../shared/material-ui-formik/FormikSelect";
import {
  createMiscellaneousCharges,
  fetchMiscellaneousChargesDetail,
} from "../../../apis/services/miscellaneousChargesApiService";
import { toast } from "react-toastify";
import { createMiscellaneousChargesValidator } from "../../../shared/validations/AdminValidator";
import Loader from "../../../layout/Loader";
import { useTranslation } from "react-i18next";

export const AddMiscellaneousCharges = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialState = {
    name: "",
    amount: "",
    type: 0,
    note:''
  };
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    var bodyFormData = {
      name: values.name || '',
      amount: values.amount || '',
      note: values.note || '',
      type: values?.type || ''
    };

    const result = await createMiscellaneousCharges(bodyFormData);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      navigate(Routing.Setting + "?tab=15");

    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  
  };

  return (
    <div className=" pl-2 pr-2 pb-2" style={{ height: "auto" }}>
      {loading && <Loader />}
      <div
        className="d-flex mt-2rem mb-2 pb-2"
        style={{ borderBottom: "2px solid", alignItems: "baseline" }}
      >
        <h1 className="pagename-heading ml-0">{t("Add Miscellaneous Charges")}</h1>
        <p className="">
          {t("Home")} / {t("Add Miscellaneous Charges")}
        </p>
      </div>

      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={()=>createMiscellaneousChargesValidator(t)}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                        <Field
                          label={t("Name")}
                          name="name"
                          id="name"
                          // defaultOption
                          placeHolder={t("Enter Name")}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="name"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={t("Amount")}
                          name="amount"
                          id="amount"
                          // defaultOption
                          placeHolder={t("Enter amount")}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="amount"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={t("Note")}
                          name="note"
                          id="note"
                          type="textarea"
                          maxLength={150}
                          // defaultOption
                          placeHolder={t("Enter note")}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="note"
                          component={ShowInputError}
                        />
                      </Grid>

                      <Grid item md={6} xs={12} sx={{paddingTop:"35px"}}>
                      <Typography
                            id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {t("Type")}
                          </Typography>
                        <Field
                          name="type"
                          placeHolder={t("Select Type")}                          
                          options={[
                            { title: "Select Type", value: 0 }, 
                            { title: "Expense", value: 1 },
                            { title: "Revenue", value: 2 },
                          ]}
                          component={FormikSelect}
                        />
                        <ErrorMessage name="type"  component={ShowInputError}/>
                          </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "20px",
                    marginBottom: "20px",
                    borderTop: "2px solid",
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={2}>
                    <Button
                      className="mt-1 comman-button"
                      size="large"
                      variant="contained"
                      disableElevation
                      //type="submit"
                      onClick={() => handleSubmit()}
                      color="primary"
                      // style={{
                      //   justifyContent: "center",
                      //   width: "98px",
                      //   height: "44px",
                      //   textTransform: "capitalize",
                      //   background:
                      //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                      // }}
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      className="ml-1 comman-cancel-button mt-1"
                      onClick={() => navigate(Routing.Setting   + "?tab=16")}
                      size="large"
                      variant="contained"
                      disableElevation
                      color="primary"
                      // style={{
                      //   justifyContent: "center",
                      //   width: "98px",
                      //   height: "44px",
                      //   textTransform: "capitalize",
                      //   background: "#757474",
                      //   marginLeft: "15px",
                      // }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                </div>
              </form>
            
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddMiscellaneousCharges);
