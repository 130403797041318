import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createMiscellaneousCharges = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.MISCELLANEOUS_CHARGES_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateMiscellaneousCharges = async (data: object, miscellaneoudId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.MISCELLANEOUS_CHARGES_RESOURCE_ROUTE + `/${miscellaneoudId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMiscellaneousChargesList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.MISCELLANEOUS_CHARGES_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMiscellaneousCharges = async (miscellaneoudId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.MISCELLANEOUS_CHARGES_RESOURCE_ROUTE + `/${miscellaneoudId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMiscellaneousChargesDetail = async (miscellaneoudId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.MISCELLANEOUS_CHARGES_RESOURCE_ROUTE + `/${miscellaneoudId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};


