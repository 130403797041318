import { CircularProgress } from '@mui/material';
import React from 'react';

const SmallLoader: React.FC = () => {
    return (
        <div id="loader" className="small-loader-wrapper">
            <CircularProgress color="primary" thickness={3} size={44} />
        </div>
    );
};

export default SmallLoader;




export const SmallLoaderData: React.FC = (colors : any) => {
    return (
        <div id="loader" className="small-loader-wrapper mt-2" style={{backgroundColor:"#ffffff"}}>
            <CircularProgress color="primary"
            sx={{  position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",}}
            thickness={3} size={44} />
        </div>
    );
};

