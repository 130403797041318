import {createSlice} from '@reduxjs/toolkit';


//here just create for miscellaneousChargesList


const initialState = {
     recurringChargeList: [],
     recurringChargeDetail: null

    };

const recurringChargeSlice = createSlice({
    name: 'recurringChargeSlice',
    initialState: initialState,
    reducers: {
        recurringChargeListSuccess: (state, action) => {
            state.recurringChargeList = action.payload;
        },
        recurringChargeListFailed: (state, action) => {
            state.recurringChargeList = [];
        },
        recurringChargeDetailSuccess: (state, action) => {
            state.recurringChargeDetail = action.payload;
        },
        recurringChargeDetailFailed: (state, action) => {
            state.recurringChargeDetail = null;        
        },
    }
});

export const {
    recurringChargeListSuccess,
    recurringChargeListFailed,
    recurringChargeDetailSuccess,
    recurringChargeDetailFailed
} = recurringChargeSlice.actions;

export default recurringChargeSlice.reducer;
