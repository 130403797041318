/* eslint-disable */

import moment from "moment";
import { permissionDetail } from "../apis/services/PermissionService";
import { useDispatch } from "react-redux";
import store from "../Reducer/store";
import { useTranslation } from "react-i18next";
const items = JSON.parse(localStorage.getItem("userDetails"));
import { nlNL, jaJP, deDE, zhCN, esES, enUS } from "@mui/x-data-grid/locales";
import CryptoJS from "crypto-js";



export const DisplayFormikState = (props) => (
  <div style={{ margin: "1rem 0" }}>
    <h3 style={{ fontFamily: "monospace" }} />
    <pre
      style={{
        background: "#f6f8fa",
        fontSize: ".65rem",
        padding: ".5rem",
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);


export const splitNumber = (num) => {
  const strNum = num.toString(); // Convert the number to a string
  const prefix = strNum.slice(0, -2); // Get all digits except the last two
  const suffix = strNum.slice(-2); // Get the last two digits
  return `${prefix}-${suffix}`;
}

export function generateGreetings() {
  const { t } = useTranslation();
  var currentHour = moment(new Date()).format("HH");

  if (currentHour >= 3 && currentHour < 12) {
    return t("Good Morning");
  } else if (currentHour >= 12 && currentHour < 15) {
    return t("Good Afternoon");
  } else if (currentHour >= 15 && currentHour < 20) {
    return t("Good Evening");
  } else if (currentHour >= 20 || currentHour < 3) {
    return t("Good Night");
  } else {
    return t("Hello");
  }
}

export const getCancellationPolicy = (type) => {
  switch (type) {
    case 1:
      // return "Free cancellation policy";
      return " Free cancellation, full refund - up to 24hr before checkin";
    case 2:
      return "Non-refundable";
    case 3:
      return "Free cancellation, full refund - up to 72hr before checkin";
    default:
      return "";
  }
};
export const getRoomStatusFromColor = (type) => {
  switch (type) {
    case "green":
      // return "Free cancellation policy";
      return "Checkin";
    case "red":
      return "Checkout";
    case "blue":
      return "In House";
    case "gray":
      return "No Booking";
    case "black":
      return "Not Found";
    default:
      return " ";
  }
};

export const getRoomStatusColor = (type) => {
  switch (type) {
    case "green":
      // return "Free cancellation policy";
      return "#33D391";
    case "red":
      return "#E8697D";
    case "blue":
      return "#6645A9";
    case "gray":
      return "#808080";
    case "black":
      return "#4D4D4D";
    default:
      return " ";
  }
};

export const cryptoDecryptData = (encryptedText) => {
  try {
    const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY; // Ensure this matches the Node.js key

    if (!secretKey) throw new Error("Missing Secret Key!");
    if (!encryptedText.includes(":")) throw new Error("Invalid encrypted format");

    const [ivHex, encryptedData] = encryptedText.split(":");

    // Convert IV and key to the required formats
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const key = CryptoJS.enc.Utf8.parse(secretKey);

    // Decrypt using CryptoJS
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedData) },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) throw new Error("Decryption failed, wrong key or corrupted data");

    return decryptedText;
  } catch (error) {
    console.error("Decryption Error:", error.message);
    return null;
  }
};

export const currencyFormater = (item) => {
  return (
    "$ " +
    (+item).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export const getLockRecordType = (type) => {
  switch (type) {
    case 1:
      return "App unlock";
    case 2:
      return "touch the parking lock";
    case 3:
      return "gateway unlock";
    case 4:
      return "passcode unlock";
    case 5:
      return "parking lock raise";
    case 6:
      return "parking lock lower";
    case 7:
      return "IC card unlock";
    case 8:
      return "fingerprint unlock";
    case 9:
      return "wristband unlock";
    case 10:
      return "mechanical key unlock";
    case 11:
      return "Bluetooth lock";
    case 12:
      return "gateway unlock";
    case 29:
      return "unexpected unlock";
    case 30:
      return "door magnet close";
    case 31:
      return "door magnet open";
    case 32:
      return "open from inside";
    case 33:
      return "lock by fingerprint";
    case 34:
      return "lock by passcode";
    case 35:
      return "lock by IC card";
    case 36:
      return "lock by Mechanical key";
    case 37:
      return "Remote Control";
    case 44:
      return "Tamper alert";
    case 45:
      return "Auto Lock";
    case 46:
      return "unlock by unlock key";
    case 47:
      return "lock by lock key";
    case 48:
      return "Use INVALID Passcode several times";
    default:
      return "";
  }
};

export const getCardType = (type) => {
  switch (type) {
    case "AX":
      return "American Express";
    case "AP":
      return "Alipay";
    case "BC":
      return "Backed Card";
    case "CI":
      return "Citgo";
    case "DB":
      return "Debit Card";
    case "GC":
      return "Gift Card";
    case "JC":
      return "JCB";
    case "MC":
      return "Mastercard";
    case "NS":
      return "Discover/JCB/Novus";
    case "PL":
      return "Private Label";
    case "SC":
      return "Sears Canada";
    case "VS":
      return "Visa";
    case "WP":
      return "WeChat Pay";
    case "YC":
      return "IT'S YOUR CARD";

      return type;
  }
};

export const getRateByDayOverview = (newItem, date) => {
  switch (moment(date || new Date()).format("dddd")) {
    case "Monday":
      return newItem[0]?.monday_price
        ? newItem[0]?.monday_price
        : newItem[0]?.default_price_for_all_day;
    case "Tuesday":
      return newItem[0]?.tuesday_price
        ? newItem[0]?.tuesday_price
        : newItem[0]?.default_price_for_all_day;
    case "Wednesday":
      return newItem[0]?.wednesday_price
        ? newItem[0]?.wednesday_price
        : newItem[0]?.default_price_for_all_day;
    case "Thursday":
      return newItem[0]?.thursday_price
        ? newItem[0]?.thursday_price
        : newItem[0]?.default_price_for_all_day;
    case "Friday":
      return newItem[0]?.friday_price
        ? newItem[0]?.friday_price
        : newItem[0]?.default_price_for_all_day;
    case "Saturday":
      return newItem[0]?.saturday_price
        ? newItem[0]?.saturday_price
        : newItem[0]?.default_price_for_all_day;
    case "Sunday":
      return newItem[0]?.sunday_price
        ? newItem[0]?.sunday_price
        : newItem[0]?.default_price_for_all_day;
    default:
      return newItem[0]?.default_price_for_all_day;
  }
};

export const getRateByDay = (categoryRateDetail, date) => {
  const newItem = categoryRateDetail?.filter((item, index) => {
    return item.accommodationDetail?.id === 1;
  });
  // console.log(categoryRateDetail,420, 950)

  switch (moment(date || new Date()).format("dddd")) {
    case "Monday":
      return newItem[0]?.monday_price
        ? newItem[0]?.monday_price
        : newItem[0]?.default_price_for_all_day;
    case "Tuesday":
      return newItem[0]?.tuesday_price
        ? newItem[0]?.tuesday_price
        : newItem[0]?.default_price_for_all_day;
    case "Wednesday":
      return newItem[0]?.wednesday_price
        ? newItem[0]?.wednesday_price
        : newItem[0]?.default_price_for_all_day;
    case "Thursday":
      return newItem[0]?.thursday_price
        ? newItem[0]?.thursday_price
        : newItem[0]?.default_price_for_all_day;
    case "Friday":
      return newItem[0]?.friday_price
        ? newItem[0]?.friday_price
        : newItem[0]?.default_price_for_all_day;
    case "Saturday":
      return newItem[0]?.saturday_price
        ? newItem[0]?.saturday_price
        : newItem[0]?.default_price_for_all_day;
    case "Sunday":
      return newItem[0]?.sunday_price
        ? newItem[0]?.sunday_price
        : newItem[0]?.default_price_for_all_day;
    default:
      return newItem[0]?.default_price_for_all_day;
  }
};

export const getPermission = async (name, num) => {
 
  const currentState = store.getState();
  // console.log(currentState, 236)

  // const result = await permissionDetail(items.role_id);
  const newItem = currentState?.auth?.permission?.data?.find((item, index) => {
    return item.module_name === name;
  });
  const permissionsList = newItem?.permissionsList.find((item, index) => {
    return item.type === num;
  });
  // if (result?.status === 200) {
  // console.log(currentState?.auth,newItem,permissionsList, 236)

  // return permissionsList;
  return permissionsList? permissionsList :{hasPermission: null};

  // }
};

export const getPermissionList = (name, num) => {
 
  const currentState = store.getState();
  // console.log(currentState, 236)

  // const result = await permissionDetail(items.role_id);
  const newItem = currentState?.auth?.permission?.data?.find((item, index) => {
    return item.module_name === name;
  });
  const permissionsList = newItem?.permissionsList.find((item, index) => {
    return item.type === num;
  });
  // if (result?.status === 200) {
  // console.log(currentState?.auth,newItem,permissionsList, 236)

  // return permissionsList;
  return permissionsList? permissionsList :{hasPermission: null};

  // }
};

export const getViewPermission = async (name, num) => {
 
  const currentState = store.getState();
  // console.log(currentState, 236)

  // const result = await permissionDetail(items.role_id);
  const newItem = currentState?.auth?.permission?.data?.find((item, index) => {
    return item.module_name === name;
  });
  const permissionsList = newItem?.permissionsList.find((item, index) => {
    return item.type === num;
  });
  // if (result?.status === 200) {
  console.log(newItem,permissionsList || null,285)
  return permissionsList? permissionsList :{hasPermission: null};
  // }
};

export const getExtraChildRateByDay = (categoryRateDetail, date) => {
  const newItem = categoryRateDetail?.filter((item, index) => {
    return item.accommodationDetail?.id === 3;
  });
  switch (moment(date || new Date()).format("dddd")) {
    case "Monday":
      return newItem[0]?.monday_price;
    case "Tuesday":
      return newItem[0]?.tuesday_price;
    case "Wednesday":
      return newItem[0]?.wednesday_price;
    case "Thursday":
      return newItem[0]?.thursday_price;
    case "Friday":
      return newItem[0]?.friday_price;
    case "Saturday":
      return newItem[0]?.saturday_price;
    case "Sunday":
      return newItem[0]?.sunday_price;
    default:
      return newItem[0]?.default_price_for_all_day;
  }
};

export const getExtraAdultRateByDay = (categoryRateDetail, date) => {
  const newItem = categoryRateDetail?.filter((item, index) => {
    return item.accommodationDetail?.id === 2;
  });
  switch (moment(date || new Date()).format("dddd")) {
    case "Monday":
      return newItem[0]?.monday_price;
    case "Tuesday":
      return newItem[0]?.tuesday_price;
    case "Wednesday":
      return newItem[0]?.wednesday_price;
    case "Thursday":
      return newItem[0]?.thursday_price;
    case "Friday":
      return newItem[0]?.friday_price;
    case "Saturday":
      return newItem[0]?.saturday_price;
    case "Sunday":
      return newItem[0]?.sunday_price;
    default:
      return newItem[0]?.default_price_for_all_day;
  }
};

export const getHouseKeeperRoomStatus = (number) => {
  switch (number) {
    case 1:
      return "Assigned";
    case 2:
      return "Working on it";
    case 3:
      return "Mark as clean";
    case 4:
      return "Dirty";
    default:
      return "";
  }
};
export const getPaymentTypeOfBooking = (number) => {
  switch (number) {
    case 1:
      return "Cash";
    case 2:
      return "Credit Card";
    case 3:
      return "Cheque";
    case 4:
      return "Direct Bill";
    default:
      return "";
  }
};

//
export const getLanguageCode = () => {
  let code =  localStorage.getItem("lng") || "en"
  switch (code) {
    case "ja":
      return jaJP;
    case "es":
      return esES;
    case "zh-CN":
      return zhCN;
    case "de":
      return deDE;
    case "nl":
      return nlNL;
    default:
      return enUS;
  }
};

export const getCardMerchantType = (number) => {
  switch (number) {
    case 1:
      return "Lodging";
    case 2:
      return "Retail";
    case 3:
      return "Food & Beverages";
    case 4:
      return "Ecomm";
    default:
      return "";
  }
};
