import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Routing } from "../../shared/constants/routing";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPermission, getRateByDayOverview } from "../../utils/helper";
import NotExist from "../About/NotExist";
import Loader from "../../layout/Loader";
import DynamicPricing from "./DynamicPricing";
import OccupancyPrediction from "./OccupancyPrediction";


const RevenueGeneration = (props) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [viewPermission, setViewPermission] = useState(null);
  const auth = useSelector((state) => state.auth);

  const getPermissions = async () => {
    const ViewPermission = await getPermission("Revenue Generation", 2);
    setViewPermission(ViewPermission);
    setInitLoading(false);
  };
  useEffect(() => {
    if (Object.keys(auth?.permission)?.length > 0) {
      getPermissions();
    }
  }, [auth]);

  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    if (!initLoading && viewPermission?.hasPermission !== null) {
      if (searchParams.get("tab")) {
        handletabs(searchParams.get("tab"));
      } else {
        handletabs("occupancy-prediction");
      }
    }
  }, [searchParams, initLoading]);
  const { t, i18n } = useTranslation();
  const [tabName, setTabName] = React.useState("Occupancy Prediction");



  const handletabs = (e) => {
    document.getElementById("occupancy-prediction").classList.remove("active-tab");
    // document.getElementById("dynamic-pricing").classList.remove("active-tab");
    document.getElementById(e).classList.add("active-tab");
    console.log('ee',e)
    setTabName(e);
    setSearchParams({ tab: e });
  };

  return (
    <>
      {viewPermission?.hasPermission !== null && !initLoading ? (
        <div className="main-layout-background h-auto">
          <div className="d-flex mt-2rem justify-content-between flex-wrap gap-2">
            <div className="d-flex align-items-center">
              <h1 className="pagename-heading">{t("Revenue Generation")}</h1>
              <p className="">
                {" "}
                {t("Home")} / {t(tabName)}
              </p>
            </div>
            <div className="pr-2">
              {/* {tabName == "dynamic-pricing" && (
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  className="comman-button"
                  color="primary"
                  onClick={() => setOpenAddHotelDialog(true)}
                >
                  {t("Add Dynamic Price")}
                </Button>
              )} */}

            </div>
          </div>
          <div className="nav-header  justify-content-start">
            <div
              id="occupancy-prediction"
              onClick={() => handletabs("occupancy-prediction")}
              className={`nav-tab h-25p text-16 active-tab`}
            >
              {t("Occupancy Prediction")}
            </div>

            {/* <div
              id="dynamic-pricing"
              onClick={() => handletabs("dynamic-pricing")}
              className={`nav-tab h-25p text-16 active-tab`}
            >
              {t("Dynamic Pricing")}
            </div> */}
          </div>
          {/* <div className="nav-header h-61p mt-1 border-bottom-0"></div> */}

          <div className="mt-3 pl-md-2 pr-md-2">
            {tabName === "occupancy-prediction" && <OccupancyPrediction/>}
            {tabName === "dynamic-pricing" && <DynamicPricing/>}
          </div>
        </div>
       ) : initLoading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          <NotExist />
        </div>
      )} 
    </>
  );
};


export default RevenueGeneration;
