import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../shared/components/ConfirmDialog";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import SearchIcon from "@mui/icons-material/Search";
import {
  createRecord,
  deleteRecord,
  fetchList,
} from "../../../apis/services/CommonApiService";
import {
  roomsBookingRestrictionListFailed,
  roomsBookingRestrictionListSuccess,
} from "../../../Reducer/roomSlice";
import { rowsPerPageJsonData } from "../../../utils/JsonData";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getLanguageCode, getPermissionList } from "../../../utils/helper";
import Loader from "../../../layout/Loader";
import { createRatesAndAvailability } from "../../../apis/services/ChannelManagerApiService";

const BookingRestrictionList = (props) => {
  const {
    roomsBookingRestrictionList,
    roomsBookingRestrictionListSuccess,
    roomsBookingRestrictionListFailed,
  } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [datagridOptions, setDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    searchSpeciality: 0,
  });

  const updatedatagridOptions = (k, v) =>
    setDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const getRoomRestrictionList = useCallback(async () => {
    // console.log("Api Call");
    updatedatagridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.ROOM_RESTRICTION_RESOURCE_ROUTE +
        `?page_no=${datagridOptions.page}&items_per_page=${datagridOptions.pageSize}&search_text=${searchFilters.searchText}`
    );
    if (result?.status === 200) {
      updatedatagridOptions("totalRows", result.data.totalRows);
      roomsBookingRestrictionListSuccess(result.data.rows);
      updatedatagridOptions("loading", false);
    } else {
      updatedatagridOptions("totalRows", 0);
      roomsBookingRestrictionListFailed();
      updatedatagridOptions("loading", false);
    }
  }, [
    datagridOptions.page,
    datagridOptions.pageSize,
    searchFilters.searchText,
  ]);

  useEffect(() => {
    getRoomRestrictionList();
  }, [getRoomRestrictionList]);

  const setDatagridRowsData = (data) => {
    const ratesDatagridRows = data?.map((item, index) => {
      return {
        id: item.id,
        name: item.roomDetail?.room_name,
        start_date: moment(item.start_date, "YYYY-MM-DD")?.format("MM/DD/YYYY"),
        end_date: moment(item.end_date, "YYYY-MM-DD")?.format("MM/DD/YYYY"),
        actions: "Actions",
      };
    });
    updatedatagridOptions("rows", ratesDatagridRows);
  };

  useEffect(() => {
    setDatagridRowsData(roomsBookingRestrictionList);
  }, [roomsBookingRestrictionList]);

  //delete room rate api
  const [openDeleteRecordDialog, setOpenDeleteRecordDialog] =
    React.useState(false);
  const handleCloseDeleteRecordDialog = () => setOpenDeleteRecordDialog(false);
  const [deleteRecordId, setDeleteRecordId] = useState(0);

  const [hotelID, setHotelID] = useState("");
  const [roomCategoryTypes, setRoomCategoryTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPropertyId();
    getRoomData();
  }, []);

  const getPropertyId = async () => {
    const userHotelID = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
      : 0;
    const result = await fetchList(
      ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
    );

    if (result?.status === 200) {
      setHotelID(result?.data?.property_id);
    } else {
      console.log(result.message.response + ", please check!");
    }
  };

  const getRoomData = async () => {
    // setLoading(true)
    const resultRe = await fetchList(ApiEndPoints.ROOM_CATEGORY_SELECT_LIST);
    const RoomData = [];
    if (resultRe?.status === 200) {
      for (var j in resultRe.data) {
        RoomData.push({
          id: resultRe.data[j].id,
          // title: resultRe.data[j].room_name,
          type: resultRe.data[j]?.room_type_short_code,
          type_id: resultRe.data[j].id,
          max_occupancy: resultRe.data[j]?.max_occupancy,
          // housekeeping: resultRe.data[j]?.houseKeepingStatus?.status,
        });
      }
      setRoomCategoryTypes(RoomData);
    } else {
      setRoomCategoryTypes([]);
    }
  };

  const handleGetAndFixAll = async (searchFilters) => {
    setLoading(true);

    const result = await fetchList(
      ApiEndPoints.GET_OTA_RATES_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${
          searchFilters.endDate
        }&ota_code=${[0]}`
    );

    const resultAvail = await fetchList(
      ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${searchFilters.endDate}`
    );

    const otaAvail = await createRecord(
      {
        hotelId: hotelID,
        startDate: searchFilters.startDate,
        endDate: searchFilters.endDate,
      },
      ApiEndPoints.OTA_AVAILABILITY_INVENTORY
    );

    let availabilityOtaData = [];
    if (result?.status === 200) {
      availabilityOtaData = result?.data;
    } else {
      setLoading(false);
    }

    let availabilityData = [];
    if (resultAvail?.status === 200) {
      availabilityData = resultAvail?.data;
    } else {
      setLoading(false);
    }

    let otaAvailInv = [];
    if (otaAvail?.status === 200) {
      otaAvailInv = otaAvail?.data;
    } else {
      setLoading(false);
    }

    let dateArray = [];

    availabilityData = availabilityData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find((itemR) => item?.catId === itemR?.id)
        ?.type,
    }));

    availabilityOtaData = availabilityOtaData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find(
        (itemR) => item?.category_id === itemR?.id
      )?.type,
    }));

    for (let i in otaAvailInv) {
      for (let j in otaAvailInv[i]) {
        let filAvailData = availabilityData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );
        let filOtaAvailData = availabilityOtaData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );

        if (
          (+filAvailData?.availableRooms < +filOtaAvailData?.availability ||
            +filOtaAvailData?.availability !== +otaAvailInv[i][j]?.inventory) &&
          filOtaAvailData?.availability
        ) {
          if (
            searchFilters?.room_type_id === 0 ||
            searchFilters?.room_type_id === j
          ) {
            dateArray?.push({
              closed: 0,
              closedonarrival: 0,
              closedondeparture: 0,
              extraadultrate: 0,
              extrachildrate: 0,
              maximumstay: 0,
              minimumstay: 0,
              price: [{ NumberOfGuests: "", value: "" }],
              rate: [{ rateplanid: "" }],
              roomid: j,
              sendAllData: 2,
              showDate: 0,
              value: i,
              roomstosell: filAvailData?.availableRooms,
            });
          }
        }
      }
      // console.log( dateArray, 119);
    }
    console.log(dateArray, 131);

    if (dateArray?.length === 0) {
      // toast.error(t("There is not any availability discrepancy"));
      setLoading(false);
      return;
    }

    let payload = {
      hotelid: hotelID,
      rooms: dateArray,
    };
    const resultCreate = await createRatesAndAvailability(payload);
    if (resultCreate?.status === 200) {
      toast.success(t(resultCreate.message));

      setLoading(false);
    } else if (resultCreate === undefined) {
      setLoading(false);
      toast.error(t(resultCreate.message));
    } else {
      setLoading(false);
      if (resultCreate?.message?.Errors?.length > 0) {
        toast.error(t(resultCreate.message.Errors[0].ShortText));
      } else {
        toast.error(t(resultCreate.message));
      }
    }
  };

  const handleConfirmDeleteRoomRateAction = async () => {
    setOpenDeleteRecordDialog(false);
    let values = datagridOptions.rows?.find(
      (item) => item?.id === deleteRecordId
    );
    console.log(values, 285);

    const result = await deleteRecord(
      deleteRecordId,
      ApiEndPoints.ROOM_RESTRICTION_RESOURCE_ROUTE
    );
    if (result?.status === 200) {
      toast.success(t(result.message));
      if (
        moment(values.end_date, "MM/DD/YYYY").isAfter(moment()) ||
        moment(values.end_date, "MM/DD/YYYY").isSame(
          moment(moment().format("MM/DD/YYYY"))
        )
      ) {
        new Promise(async (resolve) => {
          handleGetAndFixAll({
            startDate: moment(values.start_date, "MM/DD/YYYY").isAfter(moment())
              ? moment(values.start_date, "MM/DD/YYYY").format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            endDate: moment(values.end_date, "MM/DD/YYYY").format("YYYY-MM-DD"),
            room_type_id: 0,
          })
            .then(async () => {
              resolve();
              getRoomRestrictionList();
            })
            .catch((err) => console.log(err));
        });
      } else {
        getRoomRestrictionList();
      }
    } else {
      toast.error(t(result.message));
    }
  };

  const deleteRate = React.useCallback(
    (id) => () => {
      setDeleteRecordId(id);
      setOpenDeleteRecordDialog(true);
    },
    []
  );

  const datagridColumns = [
    { field: "name", headerName: t("Room Name"), flex: 1 },
    { field: "start_date", headerName: t("From"), flex: 1 },
    { field: "end_date", headerName: t("To"), flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => {
                const actions = [];
        
                // Check permission for "Edit"
                if (getPermissionList("Booking restrictions", 3)?.hasPermission) {
                  actions.push(
                    <GridActionsCellItem
          label={t("Edit")}
          onClick={() => navigate(`/edit-booking-restriction/${params.id}`)}
          showInMenu
        />
                  );
                }
        
                if (getPermissionList("Booking restrictions", 4)?.hasPermission) {
                  actions.push(
                    <GridActionsCellItem
                    label={t("Delete")}
                    onClick={deleteRate(params.id)}
                    showInMenu
                  />
                  );
                }
        
                return actions;
              },
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.length > 3) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    } else if (value.length == 0) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  const theme = useTheme();

  return (
    <div className="main-layout-background ">
      {loading && <Loader />}
      <div
        className="d-flex mb-2  justify-content-between align-items-center"
        style={{ gap: "12px" }}
      ></div>
      <Grid container spacing={2} className="px-md-3" alignItems="flex-start">
        <Grid item lg={4} xs={12} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <TextField
              label={t("Search Room")}
              onChange={(event) => {
                handleSearch(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className="mt-2 px-md-3">
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={datagridOptions.loading}
          rowCount={datagridOptions.totalRows}
          rowsPerPageOptions={datagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={datagridOptions.rows}
          columns={datagridColumns}
          page={datagridOptions.page - 1}
          pageSize={datagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updatedatagridOptions("page", 1);
            updatedatagridOptions("pageSize", pageSize);
          }}
          sx={{
            [`& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader`]: {
              [theme.breakpoints.down("sm")]: {
                // Adjust styles for small screens
                fontSize: "12px", // Adjust font size
                padding: "8px", // Adjust padding
                minWidth: "75px !important",
              },
            },
            [`& .MuiDataGrid-virtualScroller`]: {
              overflowX: "auto!important",
              overflowY: "hidden",
            },
          }}
        />
        {/* </div> */}
      </div>

      {/* Delete Room Rate Alert */}
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to delete the room restriction?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeleteRecordDialog}
        handleDialogClose={handleCloseDeleteRecordDialog}
        handleDialogAction={handleConfirmDeleteRoomRateAction}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roomsBookingRestrictionList: state.room.roomsBookingRestrictionList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomsBookingRestrictionListSuccess: (data) =>
      dispatch(roomsBookingRestrictionListSuccess(data)),
    roomsBookingRestrictionListFailed: () =>
      dispatch(roomsBookingRestrictionListFailed()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingRestrictionList);
