import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { rowsPerPageJsonData } from "../../utils/JsonData";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Loader from "../../layout/Loader";
import SearchIcon from "@mui/icons-material/Search";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { deleteRecord, fetchList } from "../../apis/services/CommonApiService";
import {
  suggestedHotelListFailed,
  suggestedHotelListSuccess,
} from "../../Reducer/roomRateSlice";
import { connect } from "react-redux";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import { toast } from "react-toastify";
import CompetitorHotelRatesDialog from "./AddDialog/CompetitorHotelRatesDialog";
import { BASE_URL, SCIENER_URL } from "../../config/Urls";
import { getLanguageCode, getPermissionList } from "../../utils/helper";
import DialogForm from "../../shared/components/DialogForm";
import moment from "moment";
import SpinnerGlobal from "../../layout/SpinnerGlobal";

const CompetitorHotels = (props) => {
  const {
    suggestedHotelList,
    suggestedHotelListSuccess,
    suggestedHotelListFailed,
  } = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [openDeleteHotelDialog, setOpenDeleteHotelDialog] = useState(false);
  const handleConfirmDeleteHotelAction = async () => {
    setLoading(true);
    setOpenDeleteHotelDialog(false);
    const result = await deleteRecord(
      deleteHotelId,
      ApiEndPoints?.DELETE_SUGGESTED_HOTEL
    );
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      getHotelList();
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };

  const [deleteHotelId, setDeleteHotelId] = useState(0);
  const deleteRate = useCallback(
    (id) => () => {
      setDeleteHotelId(id);
      setOpenDeleteHotelDialog(true);
    },
    []
  );

  const [openHotelRatesDialog, setOpenHotelRatesDialog] = useState(false);
  const [addCompitatorRatesDialog, setAddCompitatorRatessDialog] =
    useState(false);

  const handleCloseDeleteHotelDialog = () => {
    setOpenDeleteHotelDialog(false);
  };

  const [ratesDatagridOptions, setRatesDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });

  const updateRatesDatagridOptions = (k, v) =>
    setRatesDatagridOptions((prev) => ({ ...prev, [k]: v }));
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
  });

  const [compitatorFilters, setCompitatorsFilter] = useState({
    startDate: moment().add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().add(8, "days").format("YYYY-MM-DD"),
  });

  // console.log(moment(compitatorFilters?.endDate).diff(compitatorFilters?.startDate,'days'), 101)

  const handleAddCompitatorRates = async () => {
    let diff = moment(compitatorFilters?.endDate).diff(
      compitatorFilters?.startDate,
      "days"
    );
    if (diff < 0) {
      toast.error("Please, Enter Correct End Date");
      return;
    }
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.ADD_COMPETITOR_RATES +
        `?hotel_id=${ownHotelId}&days=${diff}&start_date=${compitatorFilters?.startDate}&end_date=${compitatorFilters?.endDate}`
    );
    if (result?.status === 200) {
      toast.success(result?.message);
      setAddCompitatorRatessDialog(false);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(result?.message);
    }
  };

  const ratesDatagridColumns = [
    // { field: "id", headerName: t("Id"), flex: 1 },
    { field: "hotel_id", headerName: t("Hotel Id"), flex: 1 },
    {
      field: "hotel_name",
      headerName: t("Hotel Name"),
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("Actions"),
      flex: 1,
      getActions: (params) => {
        const actions = [];
        // Check permission for "Edit"
        if (getPermissionList("Competitor Hotels", 2)?.hasPermission) {
          actions.push(
            <GridActionsCellItem
              // icon={<SecurityIcon />}
              label={t("View Rates")}
              onClick={() =>
                handleViewRates(params?.row?.hotel_id, params?.row?.hotel_name)
              }
              showInMenu
            />
          );
        }
        if (getPermissionList("Competitor Hotels", 4)?.hasPermission) {
          actions.push(
            <GridActionsCellItem
              // icon={<FileCopyIcon />}
              label={t("Delete")}
              onClick={deleteRate(params.id)}
              showInMenu
            />
          );
        }

        return actions;
      },
    },
  ];

  const getHotelList = useCallback(async () => {
    updateRatesDatagridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.GET_SUGGESTED_HOTEL_LIST +
        `?page_no=${ratesDatagridOptions.page}&items_per_page=${ratesDatagridOptions.pageSize}&search_text=${searchFilters.searchText}`
    );
    if (result?.status === 200) {
      updateRatesDatagridOptions("totalRows", result?.data?.totalRows);
      suggestedHotelListSuccess(result.data.rows);
      updateRatesDatagridOptions("loading", false);
    } else {
      // setTotalRowCount(0);
      updateRatesDatagridOptions("totalRows", 0);
      suggestedHotelListFailed();
      updateRatesDatagridOptions("loading", false);
    }
  }, [
    ratesDatagridOptions.page,
    ratesDatagridOptions.pageSize,
    searchFilters.searchText,
  ]);

  useEffect(() => {
    getHotelList();
  }, [getHotelList]);

  const setSuggestedHotels = (data) => {
    const ratesDatagridRows = data?.map((item, index) => {
      return {
        id: item?.id,
        hotel_id: item?.competitor_hotel_id,
        hotel_name: item?.competitor_hotel_name,
        actions: "Actions",
      };
    });
    updateRatesDatagridOptions("rows", ratesDatagridRows);
  };

  useEffect(() => {
    setSuggestedHotels(suggestedHotelList);
  }, [suggestedHotelList]);

  const [hotelId, setHotelId] = useState(0);
  const [hotelName, setHotelName] = useState("");

  const handleViewRates = (id, name) => {
    // console.log(id, 153)
    setOpenHotelRatesDialog(true);
    setHotelId(id);
    setHotelName(name);
  };

  const [ownHotelId, setOwnHotelId] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setOwnHotelId(items.hotel_id);
    }
  }, []);

  return (
    <div className="main-layout-background h-auto">
      {loading && <Loader />}
      <div className="d-flex mb-2 pr-2 justify-content-between align-items-center">
        <Grid
          container
          className="d-flex justify-content-between gap-2 align-items-center"
        >
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label={t("Search Hotel Name")}
                //   onChange={(event) => {
                //     handleRateSearch(event);
                //   }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            {getPermissionList("Competitor Hotels", 1)?.hasPermission && (<div className="d-flex justify-content-end">
              <Button
                variant="contained"
                disableElevation
                color="primary"
                className="comman-button"
                onClick={
                  () => setAddCompitatorRatessDialog(true)
                }
              >
                {" "}
                {t("Add Competitor Rate")}{" "}
              </Button>
            </div>)}
          </Grid>
        </Grid>
      </div>
      <div>
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={ratesDatagridOptions.loading}
          rowCount={ratesDatagridOptions.totalRows}
          rowsPerPageOptions={ratesDatagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={ratesDatagridOptions.rows}
          columns={ratesDatagridColumns}
          page={ratesDatagridOptions.page - 1}
          pageSize={ratesDatagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setRatesDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updateRatesDatagridOptions("page", 1);
            updateRatesDatagridOptions("pageSize", pageSize);
          }}
        />
        {/* </div> */}
      </div>

      <CompetitorHotelRatesDialog
        openHotelRatesDialog={openHotelRatesDialog}
        setOpenHotelRatesDialog={setOpenHotelRatesDialog}
        hotelId={hotelId}
        hotelName={hotelName}
      />

      {/* add compitator rates */}

      <DialogForm
        scroll="paper"
        maxWidth="xs"
        title={t("Add Compitator Rate")}
        openDialog={addCompitatorRatesDialog}
        handleDialogClose={() => setAddCompitatorRatessDialog(false)}
        bodyContent={
          <div className="p-2" style={{ height: "auto" }}>
            {loading && <SpinnerGlobal />}

            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500 !important",
                    }}
                  >
                    {t("Start Date")}
                  </Typography>
                  <TextField
                    type="date"
                    value={compitatorFilters?.startDate}
                    inputProps={{
                      min: moment().add(1, "days").format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => {
                      setCompitatorsFilter((prevState) => ({
                        ...prevState,
                        startDate: e.target.value,
                        endDate:
                          e.target.value === prevState?.endDate
                            ? moment(e.target.value)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            : prevState?.endDate,
                      }));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500 !important",
                    }}
                  >
                    {t("End Date")}
                  </Typography>
                  <TextField
                    type="date"
                    value={compitatorFilters?.endDate}
                    inputProps={{
                      min: moment(compitatorFilters?.startDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD"),
                      max: moment(compitatorFilters?.startDate)
                        .add(60, "days")
                        .format("YYYY-MM-DD"),
                    }}
                    onChange={(e) => {
                      setCompitatorsFilter((prevState) => ({
                        ...prevState,
                        endDate: e.target.value,
                      }));
                    }}
                  />
                  <Grid item xs={12} className="mt-2 d-flex ">
                    <Button
                      onClick={handleAddCompitatorRates}
                      className="comman-button"
                    >
                      {t("Submit")}
                    </Button>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        }
      />

      {/* Delete Room Rate Alert */}

      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to delete the Hotel data?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeleteHotelDialog}
        handleDialogClose={handleCloseDeleteHotelDialog}
        handleDialogAction={handleConfirmDeleteHotelAction}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    suggestedHotelList: state.roomRate.suggestedHotelList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    suggestedHotelListSuccess: (data) =>
      dispatch(suggestedHotelListSuccess(data)),
    suggestedHotelListFailed: () => dispatch(suggestedHotelListFailed()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompetitorHotels);
