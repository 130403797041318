import {createSlice} from '@reduxjs/toolkit';


//here just create for miscellaneousChargesList


const initialState = {
     miscellaneousChargesList: [],
     miscellaneousChargesDetail: null

    };

const miscellaneousChargesSlice = createSlice({
    name: 'miscellaneousChargesSlice',
    initialState: initialState,
    reducers: {
        miscellaneousChargesListSuccess: (state, action) => {
            state.miscellaneousChargesList = action.payload;
        },
        miscellaneousChargesListFailed: (state, action) => {
            state.miscellaneousChargesList = [];
        },
        miscellaneousChargesDetailSuccess: (state, action) => {
            state.miscellaneousChargesDetail = action.payload;
        },
        miscellaneousChargesDetailFailed: (state, action) => {
            state.miscellaneousChargesDetail = null;        
        },
    }
});

export const {
    miscellaneousChargesListSuccess,
    miscellaneousChargesListFailed,
    miscellaneousChargesDetailSuccess,
    miscellaneousChargesDetailFailed
} = miscellaneousChargesSlice.actions;

export default miscellaneousChargesSlice.reducer;
