import { Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../shared/components/ConfirmDialog";
import { Routing } from "../../../shared/constants/routing";
import { rowsPerPageJsonData } from "../../../utils/JsonData";
import {
  deleteRecord,
  fetchList,
} from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import { deleteVendorApiCall } from "../../../apis/services/VendorApiService";
import Loader from "../../../layout/Loader";
import { useTranslation } from "react-i18next";
import { getLanguageCode, getPermissionList } from "../../../utils/helper";

export const DiscountedRate = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
  });
  const [DiscountedRateDataGridOptions, setDiscountedRateDataGridOptions] =
    useState({
      loading: false,
      rows: [],
      totalRows: 0,
      rowsPerPageOptions: rowsPerPageJsonData,
      pageSize: 10,
      page: 1,
    });

  // let rateId = parseInt(
  //   window.location.pathname.split("/")[
  //     window.location.pathname.split("/").length - 1
  //   ]
  // );

  const getvendorList = useCallback(async () => {
    updatevendorsDataGridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.GET_ROOM_RATES_DISCOUNT_RATE +
        `?page_no=${DiscountedRateDataGridOptions.page}&items_per_page=${DiscountedRateDataGridOptions.pageSize}`
    );
    if (result?.status === 200) {
      updatevendorsDataGridOptions("rows", result.data.rows);
      updatevendorsDataGridOptions("totalRows", result.data.totalRows);
      updatevendorsDataGridOptions("loading", false);
    } else {
      updatevendorsDataGridOptions("rows", []);
      updatevendorsDataGridOptions("totalRows", 0);
      updatevendorsDataGridOptions("loading", false);
    }
  }, [
    DiscountedRateDataGridOptions.page,
    DiscountedRateDataGridOptions.pageSize,
    searchFilters.searchText,
    //searchFilters.roomStatus,
  ]);

  const [roomRateDetail, setRoomRateDetail] = useState({
    hotel_id: "",
    rate_name: "",
    meal_price: "",
    cancelation_policy: "",
  });

  // const getRoomRateDetail = async () => {
  //   const result = await fetchList(ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE);
  //   if (result?.status === 200) {
  //     setRoomRateDetail({
  //       hotel_id: result.data.hotel_id,
  //       rate_name: result.data.rate_name,
  //       meal_price: result.data.meal_price,
  //       cancelation_policy: result.data.cancelation_policy,
  //     });
  //   } else {
  //     setRoomRateDetail({
  //       hotel_id: "",
  //       rate_name: "",
  //       meal_price: "",
  //       cancelation_policy: "",
  //     });
  //   }
  // };

  useEffect(() => {
    // getRoomRateDetail();
    getvendorList();
  }, [getvendorList]);

  const updatevendorsDataGridOptions = (k, v) =>
    setDiscountedRateDataGridOptions((prev) => ({ ...prev, [k]: v }));

  const deletDiscountedRate = React.useCallback(
    (id) => () => {
      setDeletevendorId(id);
      setOpenDeletevendorDialog(true);
    },
    []
  );

  const editDiscountedRate = React.useCallback(
    (id) => () => {
      // console.log(id, "sdsfdsf");
      navigate(`/discounted-rate/edit/${id}`);
    },
    []
  );

  const AddDiscountedRate = React.useCallback(
    (id) => () => {
      navigate(`/discounted-rate/new/${id}`);
    },
    []
  );
  //delete room type api
  const [loading, setLoading] = React.useState(false);
  const [openDeletevendorDialog, setOpenDeletevendorDialog] =
    React.useState(false);
  const handleCloseDeleteRoomTypeDialog = () =>
    setOpenDeletevendorDialog(false);
  const [deletevendorId, setDeletevendorId] = useState(0);
  const handleConfirmDeleteVendorAction = async () => {
    setLoading(true);
    setOpenDeletevendorDialog(false);
    const result = await deleteRecord(
      deletevendorId,
      ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE + `/delete-discounted-rate`
    );
    // const result = await deleteVendorApiCall(deletevendorId);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      getvendorList();
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };
  const roomTypesDatagridColumns = [
    { field: "code", headerName: t("Code"), flex: 1 },
    { field: "name", headerName: t("Name"), flex: 1 },
    { field: "percentage", headerName: t("Percentage"), flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => {
              const actions = [];
      
              // Check permission for "Edit"
              if (getPermissionList("Discounted Rate", 3)?.hasPermission) {
                actions.push(
                  <GridActionsCellItem
                    label={t("Edit")}
                    onClick={editDiscountedRate(params.id)}
                    showInMenu
                  />
                );
              }
      
              if (getPermissionList("Discounted Rate", 4)?.hasPermission) {
                actions.push(
                  <GridActionsCellItem
                  label={t("Delete")}
                  onClick={deletDiscountedRate(params.id)}
                  showInMenu
                  />
                );
              }
      
              return actions;
            },
    },
  ];
  return (
    <div className="main-layout-background h-100vh w-100">
      {loading && <Loader />}
      {/* <div className="d-flex mt-1 pb-1 justify-content-between align-items-center"> */}
      {/* <div className="d-flex align-items-baseline">
          <h1 className="pagename-heading">Discounted Rate</h1>
          <h6 className="mt-22">Home / Room Rates / Discounted Rate</h6>
        </div>

        <div>
          <Button
            size="large"
            variant="contained"
            disableElevation
            // type="submit"
            color="primary"
            style={{
              justifyContent: "center",
              width: "191px",
              height: "34px",
              textTransform: "capitalize",
              background: "#1C3A66",
              borderRadius: "16px",
            }}
            onClick={AddDiscountedRate(rateId)}
          >
            Add Discount Rate
          </Button>
        </div> */}
      {/* </div> */}

      <div className="w-100 ">
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          style={{ marginLeft: "10px", marginBottom: "10px" }}
        >
          {/* <Grid item lg={4}>
            <Typography
              style={{
                marginBottom: "11px",
                color: "#1E1E1E",
                fontSize: "16px",
                fontWeight: "500",
              }}
              variant="h4"
            >
              Rate Name : {roomRateDetail.rate_name}
            </Typography>
          </Grid> */}
          {/* <Grid item lg={4}>
            <Typography style={{
              marginBottom: "11px",
              color: "#1E1E1E",
              fontSize: "16px",
              fontWeight: "500",
            }} variant="h4">Meal Price</Typography>
            <Typography variant="subtitle1">
              {roomRateDetail.meal_price}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography style={{
              marginBottom: "11px",
              color: "#1E1E1E",
              fontSize: "16px",
              fontWeight: "500",
            }} variant="h4">Cancelation Policy </Typography>
            <>
              {roomRateDetail.cancelation_policy === 1 ? (
                <Typography variant="subtitle2">
                  Free cancellation policy
                </Typography>
              ) : (
                <Typography variant="subtitle2">Non-refundable</Typography>
              )}
            </>
          </Grid> */}
        </Grid>
        <div style={{ width: "100%" }}>
          <DataGrid
            localeText={
              getLanguageCode().components.MuiDataGrid.defaultProps.localeText
            }
            density="compact"
            autoHeight
            getRowHeight={() => "auto"}
            pagination
            paginationMode="server"
            loading={DiscountedRateDataGridOptions.loading}
            rowCount={DiscountedRateDataGridOptions.totalRows}
            rowsPerPageOptions={
              DiscountedRateDataGridOptions.rowsPerPageOptions
            }
            // rows={ratesDatagridRows}
            rows={DiscountedRateDataGridOptions.rows}
            columns={roomTypesDatagridColumns}
            page={DiscountedRateDataGridOptions.page - 1}
            pageSize={DiscountedRateDataGridOptions.pageSize}
            checkboxSelection={true}
            onPageChange={(newPage) => {
              setDiscountedRateDataGridOptions((old) => ({
                ...old,
                page: newPage + 1,
              }));
            }}
            onPageSizeChange={(pageSize) => {
              console.log("page size", pageSize);
              updatevendorsDataGridOptions("page", 1);
              updatevendorsDataGridOptions("pageSize", pageSize);
            }}
          />
        </div>
        {/* </div> */}
      </div>

      {/* Delete Room Category Alert */}
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to Discounted Rate?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeletevendorDialog}
        handleDialogClose={handleCloseDeleteRoomTypeDialog}
        handleDialogAction={handleConfirmDeleteVendorAction}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountedRate);
