import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import images, {
  IcFlag,
  Messages,
  NotificationIcon,
  sparrowdown,
} from "../assets/images";
import NewBooking from "../pages/NewBooking/NewBooking";
import DialogForm from "../shared/components/DialogForm";
import { Routing } from "../shared/constants/routing";
import portalTheme from "../themes/App.theme";
import SellInvoice from "../pages/POS/Sales/SellInvoice";
import { useTranslation } from "react-i18next";
import { getPermission } from "../utils/helper";
import {
  getLoadingFailed,
  getLoadingSuccess,
  getMainLoadingFailed,
  getMainLoadingSuccess,
  getPermissionFailed,
  getPermissionSuccess,
  getSidebarFailed,
  getSidebarSuccess,
} from "../Reducer/authSlice";
import { permissionDetail } from "../apis/services/PermissionService";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ApiEndPoints } from "../apis/ApiEndPoints";
import { fetchList } from "../apis/services/CommonApiService";
import NotificationDialog from "./DialogComponent/NotificationDialog";
import GoogleTranslate from "./GoogleTranslate";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

const useStyles = makeStyles(() => ({
  Header: {
    zIndex: "1000 !important",
    padding: "15px 10px",
    // paddingLeft: "260px",
    height: "auto",
    boxShadow: " inset 0px -1px 0px #DFE5F1 !important",
    display: "flex !important",
    justifyContent: "center",
    backgroundColor: "#FFFFFF !important",
    [portalTheme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
    "& .MuiToolbar-root": {
      padding: "5px 30px",
    },
    "& .logo": {
      "& img": {
        height: 22,
        width: "auto",
      },
      [portalTheme.breakpoints.up("sm")]: {
        "& img": {
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .MuiAvatar-root": {
      width: 20,
      height: 20,
      [portalTheme.breakpoints.up("sm")]: {
        width: "auto",
        height: "auto",
      },
    },
  },
  MainWrapper: {
    gap: "10px",
    display: "flex",
    margin: "0 10px 0 10px",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
    "& .search-icon": {
      display: "flex",
      maxWidth: "93px",
      alignItems: "center",
    },
    "& .justify-content-between": {
      width: "260px",
    },
    "& .mx-120px": {
      maxWidth: "120px",
      width: "120px",
      justifyContent: "space-between",
    },
  },
  SearchBtn: {
    width: 17,
    height: 17,
    margin: "0 20px 0 0",
    [portalTheme.breakpoints.up("sm")]: {
      width: 18,
      height: 18,
    },
  },
  NotificationIcon: {
    width: "23px!important",
    height: "23px!important",
    [portalTheme.breakpoints.up("sm")]: {
      width: 25,
      height: 25,
    },
  },
  SearchField: {
    border: "none !important",
    width: "100px",
  },
  TextField: { width: "57px", height: "24px" },

  // Select: {
  //   "&:before": {
  //     borderColor: "white",
  //   },
  //   "&:after": {
  //     borderColor: "white",
  //   },
  // },
  ProfileBtn: {
    marginLeft: "15px",
    "& .MuiAvatar-root": {
      width: 34,
      height: 34,
      fontSize: "0.8rem",
      backgroundColor: [portalTheme.palette.primary.main],
      color: [portalTheme.palette.secondary.contrastText],
      [portalTheme.breakpoints.up("md")]: {
        width: 48,
        height: 48,
        fontSize: "1.2rem",
        lineHeight: 1,
      },
    },
  },
  UserDropDown: {
    minWidth: 204,
    marginTop: 6,
    background: "white",
    "& .header": {
      padding: "12px 16px",
      borderBottom: "1px solid #E9E9E9",
      [portalTheme.breakpoints.up("sm")]: {
        padding: "12px 22px",
      },
    },
    "& .usermenu": {
      padding: "10px 12px",
      color: [portalTheme.palette.text.primary],
      [portalTheme.breakpoints.up("sm")]: {
        padding: "13px 16px",
      },
      "& .usermenu-icon": {
        marginRight: 10,
        "& .MuiAvatar-root": {
          width: 18,
          height: 18,
          [portalTheme.breakpoints.up("sm")]: {
            width: 22,
            height: 22,
          },
        },
      },
    },
  },
}));

const Header = (props) => {
  const {
    authUserDetails,
    // authOwnerUserDetails,
    permission,
    getPermissionSuccess,
    getPermissionFailed,
    sidebarIn,
    getLoadingSuccess,
    getLoadingFailed,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [viewFutureBooking, setViewFutureBooking] = useState(null);
  const [viewPOSBooking, setViewPOSBooking] = useState(null);
  const [viewNotificationBooking, setViewNotificationBooking] = useState(null);
  const [viewPosQsr, setViewPosQsr] = useState(null);
  const [viewPosTraditional, setViewPosTraditional] = useState(null);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const getViewPermission = async () => {
    const ViewPermission = await getPermission("Booking", 2);
    const ViewPOSPermission = await getPermission("POS", 2);
    const ViewNotificationPermission = await getPermission("Notification", 2);
    const ViewPosQsr = await getPermission("QSR", 2);
    const ViewPosTraditional = await getPermission("Traditional POS", 2);
    setViewFutureBooking(ViewPermission);
    setViewPOSBooking(ViewPOSPermission);
    setViewNotificationBooking(ViewNotificationPermission);
    setViewPosTraditional(ViewPosTraditional);
    setViewPosQsr(ViewPosQsr);
  };

  const handleFetchPermission = async () => {
    const items = JSON.parse(localStorage.getItem("userDetails"));
    getLoadingSuccess();

    const result = await permissionDetail(items.role_id);
    if (result.status === 200) {
      // console.log(result, 194);
      getPermissionSuccess(result);
      getLoadingFailed();
    } else {
      getPermissionFailed();
      getLoadingFailed();
    }
  };

  const hotelData = JSON.parse(localStorage.getItem("userDetails"));

  const [notificationCount, setNotificationCount] = useState(0);

  const getNotificationCount = async () => {
    const result = await fetchList(ApiEndPoints.GET_NOTIFICATION_COUNT);
    if (result?.status === 200) {
      setNotificationCount(result?.data?.count);
    } else {
      setNotificationCount(0);
    }
  };

  const handleCloseNotificationDialog = async () => {
    setOpenNotificationDialog(false);
    getNotificationCount();
  };

  useEffect(() => {
    if (authUserDetails?.role_id) {
      handleFetchPermission();
      if (authUserDetails?.role_id !== 1) {
        getNotificationCount();
      }
    }
  }, []);

  useEffect(() => {
    if (permission) {
      // console.log(permission, 208);
      getViewPermission();
    }
  }, [permission]);

  const openUser = Boolean(userAnchorEl);
  const handleUserOpen = (event: any) => setUserAnchorEl(event.currentTarget);
  const handleUserClose = () => setUserAnchorEl(null);
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const { t, i18n } = useTranslation();
  const Langauge = [
    { name: "English", value: "en" },
    { name: "Japanese", value: "ja" },
    { name: "Spanish", value: "es" },
    { name: "Mandarin", value: "zh-CN" },
    { name: "German", value: "de" },
    { name: "Dutch", value: "nl" },
    { name: "Turkish", value: "tr" },

  ];
  const [Langauges, setLangauges] = useState(
    localStorage.getItem("lng") || "en"
  );
  const handleChangeLng = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lng", e.target.value);
    setLangauges(e.target.value);
  };

  // console.log(authUserDetails, 230);

  const [anchorPos, setAnchorPos] = useState(null);
  const open = Boolean(anchorPos);
  const handlePosClick = (event) => {
    setAnchorPos(event.currentTarget);
  };
  const handlePosClose = () => {
    setAnchorPos(null);
  };

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    if (document.body.classList.contains("show-sidebar")) {
      document.body.classList.remove("show-sidebar");
      dispatch(getSidebarFailed());
    } else {
      document.body.classList.add("show-sidebar");
      dispatch(getSidebarSuccess());
    }
  };

  return (
    <AppBar
      className={`${classes.Header} header `}
      id={"headerone"}
      sx={{ paddingLeft: sidebarIn ? "10px" : "256px" }}
      position="fixed"
      color="default"
    >
      <Box className={classes.MainWrapper} id={"headerone"}>
        {/* <div className="search-icon">
          <Avatar
            variant="square"
            sx={{ width: 20, height: 20 }}
            className={classes.SearchBtn}
            src={images.Search}
          />

          <TextField
            variant="standard"
            placeholder="Search"
            autoComplete="off"
            InputProps={{
              className: classes.SearchField,
              disableUnderline: true,
            }}
            className={classes.TextField}
          />
        </div> */}

        <div className="d-flex align-items-center">
          {sidebarIn && (
            <IconButton
              onClick={toggleSidebar}
              sx={{
                backgroundColor: "#382DAE",
                color: "#ffffff",
                ":hover": { backgroundColor: "#382DAE" },
              }}
            >
              <MenuOutlinedIcon />
            </IconButton>
          )}
          <IconButton
            className="ml-1"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: "#382DAE",
              color: "#ffffff",
              ":hover": { backgroundColor: "#382DAE" },
            }}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </div>
        <div>
          {authUserDetails && authUserDetails.role_id == 1 && (
            <div className="d-flex justify-content-end align-items-center w-100">
              <FormControl
                style={{
                  justifyContent: "center",
                  textTransform: "capitalize",
                  // left: "965px"
                  // display:"none"
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("Langauge")}
                </InputLabel>
                <Select
                  onChange={handleChangeLng}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Langauge"
                  value={Langauges}
                  sx={{ borderRadius: "12px" }}
                  // defaultValue={localStorage.getItem("lng")}
                >
                  {Langauge.map((element) => (
                    <MenuItem value={element.value} key={element.name}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {!authUserDetails?.role_id && authUserDetails?.id && (
            <div className="d-flex justify-content-end align-items-center w-100">
              <FormControl
                style={{
                  justifyContent: "center",
                  textTransform: "capitalize",
                  // left: "965px"
                  // display:"none"
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("Langauge")}
                </InputLabel>

                <Select
                  onChange={handleChangeLng}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Langauge"
                  value={Langauges}
                  sx={{ borderRadius: "12px" }}
                  // defaultValue={localStorage.getItem("lng")}
                >
                  {Langauge.map((element) => (
                    <MenuItem value={element.value} key={element.name}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {/* {authUserDetails && (
          <div className="d-flex justify-content-between align-items-center"> */}
          {/* <Button
              size="large"
              variant="contained"
              disableElevation
              // type="submit"
              color="primary"
              style={{
                justifyContent: "center",
                width: "181px",
                height: "34px",
                textTransform: "capitalize",
                background: "#1C3A66",
                borderRadius: "16px",
              }}
              // onClick={() => handleDialogOpen()}
              onClick={() => navigate(Routing.NewBooking)}
            >
              New Booking
            </Button>
            <Button
              size="large"
              variant="contained"
              disableElevation
              // type="submit"
              color="primary"
              style={{
                justifyContent: "center",
                width: "68px",
                height: "34px",
                textTransform: "capitalize",
                background: "#1C3A66",
                borderRadius: "16px",
              }}
              onClick={() => navigate(Routing.SellInvoice)}
            >
              POS
            </Button> */}
          {/* </div>
        )} */}

          {authUserDetails?.role_id && authUserDetails.role_id !== 1 && (
            <div
              className="d-flex align-items-center"
              style={{
                flexWrap: "wrap",
                height: "fit-content",
                gap: "20px",
                justifyContent: "start",
                width: "100%",
              }}
            >
              {/* <Button
              size="large"
              variant="contained"
              disableElevation
              // type="submit"
              color="primary"
              style={{
                justifyContent: "center",
                
                textTransform: "capitalize",
                background: "#1C3A66",
                borderRadius: "16px",
              }}
              onClick={() => navigate(Routing.GroupBooking)}
            >
              Group Booking
            </Button>&nbsp;&nbsp; */}
              {viewFutureBooking?.hasPermission !== null && (
                <>
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    // type="submit"
                    className="comman-button"
                    color="primary"
                    onClick={() => navigate(Routing.WalkIn)}
                  >
                    {t("Walk In")}
                  </Button>
                  {/* &nbsp;&nbsp; */}
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="comman-button"
                    color="primary"
                    onClick={() => navigate(Routing.NewBooking)}
                  >
                    {t("Future Booking")}
                  </Button>
                  {/* &nbsp;&nbsp; */}
                </>
              )}
              {hotelData?.hotelDetail?.pos_type !== 0 && (
                // viewPOSBooking?.hasPermission !== null
                <>
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    className="comman-button"
                    color="primary"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // onClick={handlePosClick}
                    onClick={() =>
                      hotelData?.hotelDetail?.pos_type === 1
                        ? navigate(Routing.SellInvoice)
                        : navigate(Routing.Pos)
                    }
                    sx={{ padding: "8px" }}
                  >
                    {t("POS")}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorPos}
                    open={open}
                    onClose={handlePosClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {hotelData?.hotelDetail?.pos_type === 1 && (
                      <MenuItem
                        onClick={() => {
                          navigate(Routing.SellInvoice);
                          handlePosClose();
                        }}
                      >
                        {t("QSR")}
                      </MenuItem>
                    )}
                    {hotelData?.hotelDetail?.pos_type === 2 && (
                      <MenuItem
                        onClick={() => {
                          navigate(Routing.Pos);
                          handlePosClose();
                        }}
                      >
                        {t("Traditional POS")}
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
              {viewNotificationBooking?.hasPermission !== null && (
                <Button
                  variant="outlined"
                  className="comman-button"
                  onClick={() => setOpenNotificationDialog(true)}
                >
                  <Badge
                    sx={{
                      [`& .MuiBadge-badge`]: {
                        backgroundColor: "#000",
                        color: "#ffffff",
                        right: "-16px",
                      },
                    }}
                    badgeContent={notificationCount?.toString()}
                  >
                    <NotificationsIcon sx={{ width: "20px", height: "20px" }} />
                  </Badge>
                </Button>
              )}
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <FormControl
                style={{
                  justifyContent: "center",
                  width: "100px",
                  textTransform: "capitalize",
                  // display:"none"
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  {t("Langauge")}
                </InputLabel>
                <Select
                  onChange={handleChangeLng}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Langauge"
                  value={Langauges}
                  sx={{ borderRadius: "12px" }}
                  // defaultValue={localStorage.getItem("lng")}
                >
                  {Langauge.map((element) => (
                    <MenuItem value={element.value} key={element.name}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <GoogleTranslate /> */}
              <Popover
                anchorEl={userAnchorEl}
                open={openUser}
                onClose={handleUserClose}
                PaperProps={{
                  className: classes.UserDropDown,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <ul>
                  <MenuItem className="usermenu">
                    <Typography variant="body1">{t("My Profile")}</Typography>
                  </MenuItem>
                </ul>
              </Popover>
            </div>
          )}
          {/* <Avatar
            variant="square"
            sx={{ width: "18px !important", height: "18px !important" }}
            src={NotificationIcon}
          />
          <Avatar
            variant="square"
            sx={{ width: "20px !important", height: "20px !important" }}
            src={Messages}
          />
          <IconButton
            className={` ${classes.ProfileBtn}`}
            size="medium"
            edge="start"
            color="inherit"
            onClick={handleUserOpen}
          > */}
          {/* <Avatar variant="circular" sx={{ width: 48, height: 48 }} src={images.UserAvatar} /> */}
          {/* <Avatar
              variant="square"
              sx={{
                width: "31px !important",
                height: "31px !important",
                borderRadius: "5px",
              }}
              src={IcFlag}
            />
          </IconButton> */}
          {/* <Popover
            anchorEl={userAnchorEl}
            open={openUser}
            onClose={handleUserClose}
            PaperProps={{
              className: classes.UserDropDown,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ul>
              <MenuItem className="usermenu">
                <Typography variant="body1">My Profile</Typography>
              </MenuItem>
            </ul>
          </Popover>
        </div> */}
        </div>
      </Box>
      <DialogForm
        scroll="paper"
        maxWidth="xl"
        title="New Booking"
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        bodyContent={<NewBooking handleDialogClose={handleDialogClose} />}
      />

      {openNotificationDialog && (
        <NotificationDialog
          openNotificationDialog={openNotificationDialog}
          handleCloseNotificationDialog={handleCloseNotificationDialog}
        />
      )}
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserDetails: state.auth.userInfo,
    // authOwnerUserDetails: state.auth.userOwnerInfo,
    permission: state.auth.permission,
    sidebarIn: state.auth.sidebarIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPermissionSuccess: (data) => {
      dispatch(getPermissionSuccess(data));
    },
    getPermissionFailed: () => dispatch(getPermissionFailed()),
    getLoadingSuccess: () => dispatch(getMainLoadingSuccess()),
    getLoadingFailed: () => dispatch(getMainLoadingFailed()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
