import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";


export const fetchOccupanyPredictionDetail = async (dates) => {
    try {
      const result = await instance({
        url: ApiEndPoints.OCCUPANCY_PREDICTION + `${dates}`,
        method: "GET",
      });
      // return result;
      return result.data;
    } catch (error) {
      throw error;
    }
  };
