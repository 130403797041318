import { Button,Grid,Typography } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShowInputError from "../../../shared/components/ShowInputError";
import { Routing } from "../../../shared/constants/routing";
import FormikInput1 from "../../../shared/material-ui-formik/FormikInput1";
import FormikSelect from "../../../shared/material-ui-formik/FormikSelect";
import {
  updateOperatingExpenses,
  fetchOperatingExpensesDetail
} from "../../../apis/services/OperatingExpensesApiService";
import { toast } from "react-toastify";
import { createOperatingExpensesValidator } from "../../../shared/validations/AdminValidator";
import Loader from "../../../layout/Loader";
import { useTranslation } from "react-i18next";
0
export const EditOperatingExpenses = (props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  let { operating_expenses_id } = useParams();
  const [operatingExpensesDetail, setOperatingExpensesDetail] = useState([]);



  const getOperatingExpensesDetail = useCallback(
    async (operating_expenses_id) => {
      const result = await fetchOperatingExpensesDetail(operating_expenses_id);
      if (result?.status === 200) {
        setOperatingExpensesDetail({
          amount: result.data.amount,
          note: result.data.note,
          operating_type: result.data.operating_type

        });
      }
      
    },
    [operating_expenses_id]
  );
  const initialState = {
    amount: operatingExpensesDetail?.amount || "",
    operating_type: operatingExpensesDetail?.operating_type || 0,
    note: operatingExpensesDetail?.note || ""
  };
  useEffect(() => {
    getOperatingExpensesDetail(operating_expenses_id);
  }, [operating_expenses_id, getOperatingExpensesDetail]);

  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (values) => {
    // eslint-disable-next-line no-console
    setLoading(true)
    var bodyFormData = {
      amount: values.amount || '',
      note: values.note || '',
      operating_type: values?.operating_type || ''
    };
    const result = await updateOperatingExpenses(bodyFormData, operating_expenses_id);
    console.log("result", result);
    console.log("message", result.message);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      navigate(Routing.Setting + "?tab=16");
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };
  return (
    <div className=" pl-2 pr-2 pb-2" style={{ height: "auto" }}>
      {loading && <Loader />}
      <div
        className="d-flex mt-2rem mb-2 pb-2"
        style={{ borderBottom: "2px solid", alignItems: "baseline" }}
      >
        <h1 className="pagename-heading ml-0">{t("Edit Operating Expenses")}</h1>
        <p className="">{t("Home")} / {t("Edit Operating Expenses")}</p>
      </div>

      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={()=>createOperatingExpensesValidator(t)}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
          
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                <Grid item md={6} xs={12} sx={{paddingTop:"35px"}}>
                      <Typography
                            id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {t("Operating Type")}
                          </Typography>
                        <Field 
                          name="operating_type"
                          placeHolder={t("Select Type")}                          
                          options={[
                            { title: "Select Type", value: 0 }, 
                            { title: "Administrative & General", value: 1 },
                            { title: "Sales & Marketing", value: 2 },
                            { title: "Repairs & Maintenance", value: 3 },
                            { title: "Utilities", value: 4 },
                          ]}
                          component={FormikSelect}
                        />
                        <ErrorMessage name="operating_type"  component={ShowInputError}/>
                          </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={t("Amount")}
                          name="amount"
                          id="amount"
                          // defaultOption
                          placeHolder={t("Enter amount")}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="amount"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          label={t("Note")}
                          name="note"
                          id="note"
                          type="textarea"
                          maxLength={150}
                          // defaultOption
                          placeHolder={t("Enter note")}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="note"
                          component={ShowInputError}
                        />
                      </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "20px",
                    marginBottom: "20px",
                    borderTop: "2px solid",
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={2}>
                    <Button
                      className="mt-1 comman-button"
                      size="large"
                      variant="contained"
                      disableElevation
                      type="submit"
                      color="primary"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      className="mt-1 ml-1 comman-cancel-button"
                      onClick={() => navigate(Routing.Setting + "?tab=16")}
                      size="large"
                      variant="contained"
                      disableElevation
                      color="primary"
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                </div>
              </form>
            
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditOperatingExpenses);
