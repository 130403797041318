import React from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label_shrinked: {
    borderRadius: "6px !important",
  },
  textField: {
    border: "1px solid #B4ADAD !important",
    borderRadius: "6px !important",
  },
  borderNone: {
    border: "0px !important",
    borderRadius: "6px !important",
  },
  textTransform: {
    textTransform: "uppercase",
  },
});

const FormikInput1 = ({ field, form, ...props }) => {
  const styles = useStyles();
  const {
    id,
    maxLength,
    multiline,
    className,
    controlClassName,
    placeHolder,
    readOnly,
    textTransform,
    sx,
    sxx,
    rows,
    label,
    borderNone,
    inputProps,
    required,
    ...rest
  } = props;

  return (
    <FormControl fullWidth className={controlClassName} sx={sx}>
      {label && (
        <Typography
          id="font-600"
          style={{
            marginBottom: "11px",
            color: "#1E1E1E",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {label} {required && (<span style={{color:"red"}}>*</span>)}
        </Typography>
      )}
      <TextField
        {...field} // Spread Formik's field props (name, value, onChange, etc.)
        {...rest} // Spread additional custom props
        id={id}
        className={className}
        placeholder={placeHolder || ""}
        multiline={multiline}
        rows={rows}
        sx={sxx}
        autoComplete="off"
        InputLabelProps={{ shrink: Boolean(field.value) }} // Shrink label if value exists
        inputProps={{
          maxLength: maxLength || 100,
          className: textTransform
            ? styles.textTransform
            : borderNone
            ? styles.borderNone
            : styles.textField,
          readOnly: readOnly,
          ...inputProps, // Spread any custom inputProps
        }}
      />
    </FormControl>
  );
};

export default FormikInput1;
