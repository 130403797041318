import { useTranslation } from "react-i18next";
import DialogForm from "../../shared/components/DialogForm";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { createRecord, fetchList } from "../../apis/services/CommonApiService";
import moment from "moment";
import { toast } from "react-toastify";
import CustomSwitchSelector from "../../shared/components/CustomSwitchSelector";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from "react-router-dom";
import { Routing } from "../../shared/constants/routing";
import { getPermissionList } from "../../utils/helper";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

const NotificationDialog = (props) => {
  let { openNotificationDialog, handleCloseNotificationDialog } = props;

  let { t } = useTranslation();
  const navigate = useNavigate();


  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState("");
  const [searchFilters, setSearchFilters] = useState({
    startDate: moment().subtract(15,'days').format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    notification_type: 0,
    is_read: 0,
  });

  const itemPerPage = 10;

  const [selectedNoti, setSelectedNoti] = useState([]);

  const getNotificationList = async () => {
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.GET_NOTIFICATION_LIST +
        `?page_no=${page}&items_per_page=${itemPerPage}&is_read=${searchFilters?.is_read}&start_date=${searchFilters?.startDate}&end_date=${searchFilters?.endDate}&notification_type=${searchFilters?.notification_type}`
    );
    if (result?.status === 200) {
      setLoading(false);

      setNotificationList([...notificationList, ...result?.data?.rows]);
      setCount(+result?.data?.totalRows - 10 - +notificationList?.length);
      setPage(page + 1);
    } else {
      setLoading(false);
      setCount(0);
    }
  };

  // console.log(selectedNoti, 42);

  useEffect(() => {
    getNotificationList();
  }, [searchFilters]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      // If scroll reaches the bottom, fetch next page data
      getNotificationList();
    }
  };

  const handleDiscardNotify = async (id, all) => {
    setLoading(true);
    const result = await createRecord(
      {
        notification_ids: id === 0 ? selectedNoti : [id],
        mark_all_as_read: all,
      },
      ApiEndPoints.DISCARD_NOTIFICATION
    );
    if (result?.status === 200) {
      setLoading(false);
      if(id === 0){
        setNotificationList((prev) => prev?.filter((item) => !selectedNoti?.includes(item?.id)))
        setSelectedNoti([]);
    } else {
          setNotificationList((prev) => prev?.filter((item) => item?.id !== id));
      }
    } else {
      toast.error(t(result.message));
      setLoading(false);
    }
  };

  const handleChangeReadUnread = (e) => {
    setSelectedNoti([]);
    setNotificationList([]);
    setCount(0);
    setPage(1);
    setSearchFilters((prev) => ({
      ...prev,
      is_read: e,
    }));
  };

  const  handleGetTypeRedirect = (val, id) => {
    var answer = "";
    switch( val ) {
      case 1:
        navigate(Routing.HouseKeeping);
        break;
      case 2:
        if(id){
          navigate(`/reservation/bookingDetail/${id}`);
          break;
        }else{
        navigate(Routing.DepartureList);
        // answer = "Checkout Not Done";
        break;
        }
      case 3:
        if(id){
          navigate(`/reservation/bookingDetail/${id}`);
          break;
        }else{
          navigate(Routing.CancellationList);
          // answer = "Booking Cancel";
          break;
        }
      case 4:
        if(id){
          navigate(`/reservation/bookingDetail/${id}`);
          break;
        }else{
        navigate(Routing.CheckInList);

        // answer = "CheckIn";
        break;
        }
      case 5:
        if(id){
          navigate(`/reservation/bookingDetail/${id}`);
          break;
        }else{
        navigate(Routing.CheckOutList);

        // answer = "Checkout";
        break;
        }
     
      default:
        return;
    }
    handleCloseNotificationDialog();
                setNotificationList([]);
                setPage(1);
                setCount(0);
    // return answer;
  }


  const  handleGetType = (val) => {
    var answer = "";
    switch( val ) {
      case 1:
        answer = "Room Not Clean on Checkin";
        break;
      case 2:
        answer = "Checkout Not Done";
        break;
      case 3:
        answer = "Booking Cancel";
        break;
      case 4:
        answer = "CheckIn";
        break;
      case 5:
        answer = "Checkout";
        break;
     
      default:
        answer = "Unknown";
    }
    return answer;
  }

  return (
    // <DialogForm
    //   scroll="paper"
    //   // maxWidth="lg"
    //   maxWidth="md"
    //   title={t("Notifications")}
    //   openDialog={openNotificationDialog}
    //   handleDialogClose={() => {
    //     handleCloseNotificationDialog();
    //     setNotificationList([]);
    //     setPage(1);
    //     setCount(0);
    //   }}
    //   bodyContent={
        <Drawer
        // variant="persistent"
        anchor={"right"}
        size={"md"}
        open={openNotificationDialog}
        onClose={() => {
                handleCloseNotificationDialog();
                setNotificationList([]);
                setPage(1);
                setCount(0);
              }}
      >
        
         <DrawerHeader id="green-back" sx = {{ minWidth:"360px" }}>
          <IconButton onClick={() => {
                handleCloseNotificationDialog();
                setNotificationList([]);
                setPage(1);
                setCount(0);
              }}>
            {<ChevronRightIcon sx={{color:"#ffffff!important"}} /> }
          </IconButton>
            <Typography sx={{color:"#ffffff!important"}} variant="h3" > 
                {t("Notification")}
            </Typography>
        </DrawerHeader>
        <Divider />
        <div className="p-2" style={{ overflowY: "auto" }}>
          {loading && <Loader />}

          <Grid container spacing={2} alignItems="flex-start" sx={{ marginBottom: "20px" }}>
            {/* <Grid item lg={6} md={6} sm={6} >
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500 !important",
                  }}
                >
                  {t("Start Date")}
                </Typography>
                <TextField
                  type="date"
                  value={searchFilters.startDate}
                  onChange={(e) => {
                    setSearchFilters((prevState) => ({
                      ...prevState,
                      startDate: e.target.value,
                      endDate: moment(searchFilters?.endDate).isAfter(
                        e.target.value
                      )
                        ? searchFilters?.endDate
                        : moment(
                            moment(e.target.value, "YYYY-MM-DD").add({ days: 0 })
                          ).format("YYYY-MM-DD")
                    }));
                    setPage(1);
                    setNotificationList([]);
                    setCount(0);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500 !important",
                  }}
                >
                  {t("End Date")}
                </Typography>
                <TextField
                  type="date"
                  value={searchFilters.endDate}
                  inputProps={{
                    min: moment(
                      moment(searchFilters.startDate, "YYYY-MM-DD").add({
                        days: 1,
                      })
                    ).format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => {
                    setSearchFilters((prevState) => ({
                      ...prevState,
                      endDate: e.target.value,
                    }));
                    setPage(1);
                    setNotificationList([]);
                    setCount(0);
                  }}
                />
              </FormControl>
            </Grid> */}
            <Grid item lg={12} md={12} sm={12} >
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "500 !important",
                  }}
                >
                  {t("Type")}
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={searchFilters.notification_type}
                  //label="Room Status"
                  onChange={(e) => {
                    setSearchFilters((prevState) => ({
                      ...prevState,
                      notification_type: e.target.value,
                    }));
                    setPage(1);
                    setNotificationList([]);
                    setCount(0);
                  }}
                >
                  <MenuItem value={0}>{t("Select Type")}</MenuItem>
                  <MenuItem value={1}>{t("Room Not Clean on Checkin")}</MenuItem>
                  <MenuItem value={2}>{t("Checkout Not Done")}</MenuItem>
                  <MenuItem value={3}>{t("Booking Cancel")}</MenuItem>
                  <MenuItem value={4}>{t("CheckIn")}</MenuItem>
                  <MenuItem value={5}>{t("Checkout")}</MenuItem>
                  {/* {bookingSource?.map((data) => (
                  <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                ))} */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ width: "fit-content" }}>
            <CustomSwitchSelector
              value={searchFilters?.is_read}
              onChange={handleChangeReadUnread}
              options={[
                {
                  label: "Unread",
                  value: 0,
                },
                {
                  label: "Read",
                  value: 1,
                },
              ]}
              selectedBackgroundColor={"#ffffff"}
              selectedFontColor={"#000"}
            />
          </div>
          {(!(searchFilters?.is_read !== 0 || notificationList?.length === 0)) && (
            <div className="mt-1 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center ">
                <Checkbox
                  checked={
                    selectedNoti?.length === notificationList?.length &&
                    notificationList?.length !== 0
                  }
                  onChange={(e) => {
                    e.target.checked
                      ? setSelectedNoti(
                          notificationList?.map((item) => item?.id)
                        )
                      : setSelectedNoti([]);
                  }}
                />
                {selectedNoti?.length === 0
                  ? t("Select All")
                  : selectedNoti?.length + t(" Selected")}
              </div>

              {getPermissionList("Notification", 3)?.hasPermission && (<div className="pr-1">
                <span
                  style={{ color: "#205A74", cursor: "pointer" }}
                  onClick={() => handleDiscardNotify(0, 0)}
                >
                  {t("Selected Mark As Read")}
                </span>
              </div>)}
            </div>
          )}

          {notificationList?.map((item, ix) => (
            <React.Fragment key={ix}>
              <div className="py-1">
                <Alert
                  sx={{
                    border: "1px solid #dfdfdf",
                    "&:hover": {
                      background: "#efefef",
                    },
                    color: "#000000",
                  }}
                  variant="outlined"
                  severity="info"
                  icon={
                    searchFilters?.is_read === 0 && (
                      <Checkbox
                        checked={selectedNoti?.includes(item?.id)}
                        onChange={(e) => {
                          e.target.checked
                            ? setSelectedNoti([...selectedNoti, item?.id])
                            : setSelectedNoti(
                                selectedNoti?.filter((el) => item?.id !== el)
                              );
                        }}
                      />
                    )
                  }
                  action={
                    searchFilters?.is_read === 0 && getPermissionList("Notification", 3)?.hasPermission  && (
                      <div className="h-100 d-flex align-items-end">
                        <Button
                          onClick={() => handleDiscardNotify(item?.id, 0)}
                          color="inherit"
                          className="comman-button"
                          size="small"
                        >
                          {t("Mark As Read")}
                        </Button>
                      </div>
                    )
                  }
                >
                  {" "}
                  <AlertTitle style={{cursor:"pointer", 
                  // "&:hover": {
                      color: "#38E078",
                      
                    // },
                  }}
                  onClick={()=>handleGetTypeRedirect(item?.notification_type, item?.booking_id)}
                  >{t(handleGetType(item?.notification_type))}</AlertTitle>
                  <div style={{cursor:"pointer"}}
                  onClick={()=>handleGetTypeRedirect(item?.notification_type, item?.booking_id)}
                  >{item?.notification}</div>
                  <div
                    className="d-flex justify-content-start mt-1"
                    style={{
                      fontSize: "smaller",
                    }}
                  >
                    {moment(item?.createdAt)?.calendar()}
                  </div>
                </Alert>
              </div>
            </React.Fragment>
          ))}
          {count > 0 && (
            <div className="d-flex justify-content-center">
              <span
                style={{ cursor: "pointer", color: "#014361" }}
                onClick={handleScroll}
              >
                {t("See More...")} ( {count} )
              </span>
            </div>
          )}

          {
            notificationList?.length === 0 && (<div 
            className="d-flex justify-content-center align-items-center"
            style={{height:"350px"}}>
                {t("Empty")}
            </div>)
          }
        </div>
        </Drawer>
    // {/* //   } */}
    // {/* // /> */}
  );
};

export default NotificationDialog;
