import {createSlice} from '@reduxjs/toolkit';


//here just create for attractionlist


const initialState = {
    attractionList: [],
    attractionDetail: null
    };

const attractionDataSlice = createSlice({
    name: 'attractionData',
    initialState: initialState,
    reducers: {
        attractionListSuccess: (state, action) => {
            state.attractionList = action.payload;
        },
        attractionListFailed: (state, action) => {
            state.attractionList = [];
        },
        attractionDetailSuccess: (state, action) => {
            console.log('attractionDetailSuccess', action.payload);
            state.attractionDetail = action.payload;
        },
        attractionDetailFailed: (state, action) => {
            state.attractionDetail = null;        
        },
    }
});

export const {
    attractionListSuccess,
    attractionListFailed,
    attractionDetailSuccess,
    attractionDetailFailed
} = attractionDataSlice.actions;

export default attractionDataSlice.reducer;
