import { getPermissionList } from "../../utils/helper";

export const routingPermission = () => ({
  Initial: {
    path: `/`,
    permission: true,
  },
  Login: {
    path: `/login`,
    permission: true,
  },
  Signup: {
    path: `/signup`,
    permission: true,
  },
  ForgotPassword: {
    path: `/forgot-password`,
    permission: true,
  },
  ResetPassword: {
    path: `/reset-password`,
    permission: true,
  },
  LoginHotelDetails: {
    path: `/hotel-details`,
    permission: true,
  },
  Dashboard: {
    path: `/dashboard`,
    permission: getPermissionList("Dashboard", 2)?.hasPermission,
  },
  Reservation: {
    path: `/reservation`,
    permission: getPermissionList("Reservation", 2)?.hasPermission,
  },
  NonAssignedRooms: {
    path: `/non-assigned-rooms`,
    permission: true,
  },
  ReservationGuestView: {
    path: `/reports/guest-reservation-list/:id`,
    permission: true,
  },
  NewBooking: {
    path: `/new-booking`,
    permission: getPermissionList("Booking", 1)?.hasPermission,
  },
  SellInvoice: {
    path: `/sell-invoice`,
    permission: true,
  },
  TraditionalSellInvoice: {
    path: `/traditional-sell-invoice`,
    permission: true,
  },
  Pos: {
    path: `/pos`,
    permission: true,
  },
  POSReports: {
    path: `/pos-reports`,
    permission: true,
  },
  RoomRates: {
    path: `/roomrates`,
    permission: getPermissionList("Room rates", 2)?.hasPermission,
  },
  Guest: {
    path: `/guest`,
    permission: getPermissionList("Guests", 2)?.hasPermission,
  },
  NewGuest: {
    path: `/guest/new`,
    permission: getPermissionList("Guests", 1)?.hasPermission,
  },
  EditGuest: {
    path: `/guest/edit/:id`,
    permission: getPermissionList("Guests", 3)?.hasPermission,
  },
  Calendar: {
    path: `/calendar`,
    permission: getPermissionList("Calendar", 3)?.hasPermission,
  },
  GroupBooking: {
    path: `/GroupBooking`,
    permission: true,
  },

  //reports
  Reports: {
    path: `/reports`,
    permission: true,
  },
  VacantRoomList: {
    path: `/reports/vacant-room-list`,
    permission: true,
  },
  ArrivalList: {
    path: `/reports/arrival-list`,
    permission: true,
  },
  CheckInList: {
    path: `/reports/checkin-list`,
    permission: true,
  },
  CheckOutList: {
    path: `/reports/checkout-list`,
    permission: true,
  },
  RoomAvailabilityList: {
    path: "/reports/room-availability-list",
    permission: true,
  },
  HouseKeepingList: {
    path: "/reports/house-keeping-list",
    permission: true,
  },

  GuestProfileList: {
    path: "/report/guest-profile-list",
    permission: true,
  },
  DepartureList: {
    path: `/reports/departure-list`,
    permission: true,
  },
  RevenueBySourceList: {
    path: "/reports/revenue-by-source",
    permission: true,
  },
  CancellationList: {
    path: `/reports/cancellation-list`,
    permission: true,
  },
  InHouseList: {
    path: `/reports/in-house-list`,
    permission: true,
  },
  InHouseListOld: {
    path: `/reports/in-house-list-old`,
    permission: true,
  },

  NoShowList: {
    path: `/reports/no-show-list`,
    permission: true,
  },
  GuestsList: {
    path: `/reports/guests-list`,
    permission: true,
  },
  RevenueByRoomTypesList: {
    path: `/reports/revenue-by-room-type-list`,
    permission: true,
  },
  RevenueByRoomTypesListNew: {
    path: `/reports/revenue-by-room-type-list-new`,
    permission: true,
  },
  AverageDailyRateList: {
    path: `/reports/average-daily-rate-list`,
    permission: true,
  },
  RateList: {
    path: "/reports/rate-list",
    permission: true,
  },
  HotalStatisticsList: {
    path: `/reports/hotal-statistics-list`,
    permission: true,
  },
  FlashList: {
    path: "/reports/flash-list",
    permission: true,
  },
  ADRRevparList: {
    path: "/reports/adr-revpar-list",
    permission: true,
  },
  ForecastList: {
    path: "/reports/forecast-list",
    permission: true,
  },

  ViewGuest: {
    path: "/guest/view-guest/:guestId",
    permission: getPermissionList("Guests", 2)?.hasPermission,
  },

  HouseKeeping: {
    path: `/housekeeping`,
    permission: getPermissionList("Housekeeping", 2)?.hasPermission,
  },
  HouseKeepingNew: {
    path: `/housekeepingNew`,
    permission: getPermissionList("Housekeeping", 2)?.hasPermission,
  },
  HouseKeeper: {
    path: `/housekeeper`,
    permission: getPermissionList("Housekeeper", 2)?.hasPermission,
  },
  HouseKeeperCleanRoom: {
    path: `/housekeeper/:id`,
    permission: getPermissionList("Housekeeper", 3)?.hasPermission,
  },
  Admin: {
    path: `/admin`,
    permission: getPermissionList("Admin", 2)?.hasPermission,
  },
  Accounting: {
    path: `/accounting`,
    permission: true,
  },
  CashBook: {
    path: `/cashbook`,
    permission: true,
  },
  Setting: {
    path: `/setting`,
    permission: getPermissionList("Setting", 2)?.hasPermission,
  },
  HotelList: {
    path: `/hotels`,
    permission: true,
  },
  CreateHotel: {
    path: `/hotel-create`,
    permission: true,
  },
  EditHotel: {
    path: `/edit-hotel/:hotelId`,
    permission: true,
  },
  ViewHotel: {
    path: `/view-hotel/:hotelId`,
    permission: true,
  },
  ViewLoginHotel: {
    path: `/view-login-hotel/:hotelId`,
    permission: true,
  },
  EditRoomCategory: {
    path: `/edit-room-category/:roomCatId`,
    permission: getPermissionList("Room types", 3)?.hasPermission,
  },
  AddRoomCategory: {
    path: `/add-room-category`,
    permission: getPermissionList("Room types", 1)?.hasPermission,
  },
  AddRoom: {
    path: `/add-room`,
    permission: getPermissionList("Rooms", 1)?.hasPermission,
  },
  EditRoom: {
    path: `/edit-room/:roomId`,
    permission: getPermissionList("Rooms", 3)?.hasPermission,
  },
  CreateRoomRates: {
    path: `/create-room-rates`,
    permission: getPermissionList("Room rates", 1)?.hasPermission,
  },
  EditRoomRates: {
    path: `/edit-room-rates/:rateId`,
    permission: getPermissionList("Room rates", 3)?.hasPermission,
  },
  EditRoomRatesDateWise: {
    path: `/edit-room-rates-datewise`,
    permission: true,
  },

  DiscountedRate: {
    path: `/discounted-rate/:id`,
    permission: getPermissionList("Discounted Rate", 1)?.hasPermission,
  },
  AddDiscountedRate: {
    path: `/discounted-rate/new`,
    permission: getPermissionList("Discounted Rate", 1)?.hasPermission,
  },
  EditDiscountedRate: {
    path: `/discounted-rate/edit/:rateId`,
    permission: getPermissionList("Discounted Rate", 3)?.hasPermission,
  },

  //group information
  GroupInformation: {
    path: `/group-information`,
    permission: getPermissionList("Group Info", 2)?.hasPermission,
  },
  AddGroupInformation: {
    path: `/group-information/add`,
    permission: getPermissionList("Group Info", 1)?.hasPermission,
  },
  EditGroupInformation: {
    path: `/group-information/edit/:id`,
    permission: getPermissionList("Group Info", 3)?.hasPermission,
  },

  WalkIn: {
    path: `/walk-in`,
    permission: getPermissionList("Reservation", 1)?.hasPermission,
  },

  CreateSeasonalRates: {
    path: `/create-seasonal-room-rates`,
    permission: getPermissionList("Seasonal Rates", 1)?.hasPermission,
  },
  EditSeasonalRoomRates: {
    path: `/edit-seasonal-room-rates/:rateId`,
    permission: getPermissionList("Seasonal Rates", 3)?.hasPermission,
  },

  CreateBookingRestriction: {
    path: `/create-booking-restriction`,
    permission: getPermissionList("Booking restrictions", 1)?.hasPermission,
  },
  EditBookingRestriction: {
    path: `/edit-booking-restriction/:restrictionId`,
    permission: getPermissionList("Booking restrictions", 3)?.hasPermission,
  },

  Products: {
    path: "/products",
    permission: getPermissionList("Products", 2)?.hasPermission,
  },
  NewProducts: {
    path: "/products/new",
    permission: getPermissionList("Products", 1)?.hasPermission,
  },
  EditProducts: {
    path: "/products/edit/:id",
    permission: getPermissionList("Products", 3)?.hasPermission,
  },
  NewPOSAddOn: {
    path: "/pos-addon/new",
    permission: getPermissionList("POS Addon", 1)?.hasPermission,
  },
  EditPOSAddOn: {
    path: "/pos-addon/edit/:id",
    permission: getPermissionList("POS Addon", 3)?.hasPermission,
  },

  ProductTypes: {
    path: "/producttypes",
    permission: getPermissionList("Product Types", 2)?.hasPermission,
  },
  NewProductTypes: {
    path: "/product-types/new",
    permission: getPermissionList("Product Types", 1)?.hasPermission,
  },
  EditProductTypes: {
    path: "/product-types/edit/:typeId",
    permission: getPermissionList("Product Types", 3)?.hasPermission,
  },

  Tax: {
    path: "/tax",
    permission: getPermissionList("Tax", 3)?.hasPermission,
  },
  NewTax: {
    path: "/tax/new",
    permission: getPermissionList("Tax", 1)?.hasPermission,
  },
  EditTax: {
    path: "/tax/edit/:taxId",
    permission: getPermissionList("Tax", 3)?.hasPermission,
  },

  Vendors: {
    path: "/vendors",
    permission: getPermissionList("Vendors", 2)?.hasPermission,
  },
  NewVendor: {
    path: "/vendors/new",
    permission: getPermissionList("Vendors", 1)?.hasPermission,
  },
  EditVendor: {
    path: "/vendors/edit/:id",
    permission: getPermissionList("Vendors", 3)?.hasPermission,
  },
  NewPoOrder: {
    path: "/po-order/new",
    permission: getPermissionList("PO Order", 1)?.hasPermission,
  },
  EditPoOrder: {
    path: "/po-order/edit/:id",
    permission: getPermissionList("PO Order", 3)?.hasPermission,
  },
  ViewPoOrder: {
    path: "/po-order/view/:id",
    permission: getPermissionList("PO Order", 2)?.hasPermission,
  },
  AdjustStock: {
    path: "/adjust-stock",
    permission: getPermissionList("Adjust Stock", 1)?.hasPermission,
  },
  AdjustStockView: {
    path: "/adjust-stock/view/:id",
    permission: getPermissionList("Adjust Stock", 3)?.hasPermission,
  },

  Sale: {
    path: "/sale",
    permission: true,
  },
  Expense: {
    path: "/expense",
    permission: true,
  },
  SalesList: {
    path: `/saleslist`,
    permission: getPermissionList("Sales List", 2)?.hasPermission,
  },
  VisitorSalesList: {
    path: `/visitorsaleslist`,
    permission: getPermissionList("Visitor Sales List", 2)?.hasPermission,
  },
  VisitorViewSales: {
    path: `/visitorsaleslist/:room_no`,
    permission: getPermissionList("Visitor Sales List", 2)?.hasPermission,
  },
  ViewSales: {
    path: `/viewsales/:bookingid/:room_no`,
    permission: getPermissionList("Sales List", 2)?.hasPermission,
  },
  PrintPOSsales: {
    path: `/print-invoice-pos/:bookingid/:room_no`,
    permission: true,
  },
  ViewReservation: {
    path: "/reservation/bookingDetail/:id",
    permission: getPermissionList("Reservation", 2)?.hasPermission,
  },
  ViewReservationFolio: {
    path: "/reservation/folioDetail/:id",
    permission: getPermissionList("Reservation", 2)?.hasPermission,
  },
  EditReservation: {
    path: "/reservation/edit/:id",
    permission: getPermissionList("Reservation", 3)?.hasPermission,
  },

  CompanyProfile: {
    path: "/companyprofile",
    permission: getPermissionList("Company Profile", 2)?.hasPermission,
  },
  NewCompanyProfile: {
    path: "/companyprofile/new",
    permission: getPermissionList("Company Profile", 1)?.hasPermission,
  },
  EditCompanyProfile: {
    path: "/companyprofile/edit/:id",
    permission: getPermissionList("Company Profile", 3)?.hasPermission,
  },

  Lock: {
    path: "/lock",
    permission: getPermissionList("Device Management", 2)?.hasPermission,
  },
  ViewLock: {
    path: "/lock/view-lock",
    permission: getPermissionList("Device Management", 2)?.hasPermission,
  },
  ViewLockRecords: {
    path: "/lock/view-lock-records",
    permission: getPermissionList("Device Management", 2)?.hasPermission,
  },

  ChannelManagement: {
    path: "/channal-management",
    permission: getPermissionList("Channle Management", 2)?.hasPermission,
  },
  RoomRate: {
    path: "/channal-management/room-rates/:channelId/:propertyId",
    permission: true,
  },
  Availability: {
    path: "/channal-management/availability/:channelId/:propertyId",
    permission: true,
  },
  IframeCall: {
    path: "/iframe-call",
    permission: getPermissionList("Connected OTAs", 2)?.hasPermission,
  },
  AddOta: {
    path: "/add-ota",
    permission: getPermissionList("Connected OTAs", 1)?.hasPermission,
  },
  EditOta: {
    path: "/edit-ota/:id",
    permission: getPermissionList("Connected OTAs", 3)?.hasPermission,
  },
  RoomType: {
    path: "/channal-management/room-type",
    permission: getPermissionList("Channle Room Type", 2)?.hasPermission,
  },
  CreateRoomType: {
    path: "/channal-management/add-room-type/:roomTypeID",
    permission: getPermissionList("Channle Room Type", 1)?.hasPermission,
  },
  RatePlan: {
    path: "/channal-management/rate-plan",
    permission: getPermissionList("Channle Rate Plan", 2)?.hasPermission,
  },
  CreateRatePlan: {
    path: "/channal-management/add-rate-plan/:ratePlanID",
    permission: getPermissionList("Channle Rate Plan", 1)?.hasPermission,
  },
  CreateRatesAndAvailability: {
    path: "/channal-management/add-rates-and-availability",
    permission: getPermissionList("Channal Rates And Availability", 1)
      ?.hasPermission,
  },

  NightReports: {
    path: "/night-reports",
    permission: true,
  },
  NightReportsList: {
    path: "/reports",
    permission: true,
  },
  // /night-reports/list",

  // Data Migrating

  DataMigration: {
    path: "/data-migration",
    permission: getPermissionList("Data Migration", 1)?.hasPermission,
  },

  HotelJournalSummary: {
    path: "/reports/night-reports/hotel-journal-summary",
    permission: true,
  },
  ShiftReconciliationCloseout:
    "/reports/night-reports/shift-Reconciliation-closeout",
  NightAuditNoShowReport: {
    path: "/reports/night-reports/night-audit-no-show-report",
    permission: true,
  },
  TaxExemptReportStats: {
    path: "/reports/night-reports/tax-exempt-report-stats",
    permission: true,
  },
  FinalTransactionCloseout: {
    path: "/reports/night-reports/final-transaction-closeout",
    permission: true,
  },
  NightAuditHotelStatastics:
    "/reports/night-reports/night-audit-hotel-statastics",
  CheckInNightReport: {
    path: "/reports/night-reports/check-in-night-report",
    permission: true,
  },
  CheckOutNightReport: {
    path: "/reports/night-reports/check-out-night-report",
    permission: true,
  },
  LedgerSummary: {
    path: "/reports/night-reports/ledger-summary",
    permission: true,
  },
  PrePaidReservations: {
    path: "/reports/night-reports/pre-paid-reservations",
    permission: true,
  },
  InHouseListNew: {
    path: "/reports/night-reports/in-house-list-new",
    permission: true,
  },
  FlashNightList: {
    path: "/reports/night-reports/flash-list",
    permission: true,
  },

  Terms: {
    path: "/about/terms",
    permission: true,
  },
  Privacy: {
    path: "/about/privacy",
    permission: true,
  },
  Contact: {
    path: "/about/contact",
    permission: true,
  },

  NewAddOn: {
    path: "/add-On/new",
    permission: getPermissionList("Add-On", 1)?.hasPermission,
  },
  EditAddOn: {
    path: "/add-On/edit/:id",
    permission: getPermissionList("Add-On", 3)?.hasPermission,
  },

  NewPropertyMaintenance: {
    path: "/add-property-maintenance",
    permission: getPermissionList("Property Maintenance", 1)?.hasPermission,
  },
  EditPropertyMaintenance: {
    path: "/edit-property-maintenance/:id",
    permission: getPermissionList("Property Maintenance", 3)?.hasPermission,
  },

  NewOccCri: {
    path: "/occ-rate/new",
    permission: getPermissionList("Occupancy Rate Criteria", 1)?.hasPermission,
  },
  EditOccCri: {
    path: "/occ-rate/edit/:id",
    permission: getPermissionList("Occupancy Rate Criteria", 3)?.hasPermission,
  },

  NewSpeCri: {
    path: "/spe-rate/new",
    permission: getPermissionList("Special Rate Criteria", 1)?.hasPermission,
  },
  EditSpeCri: {
    path: "/spe-rate/edit/:id",
    permission: getPermissionList("Special Rate Criteria", 3)?.hasPermission,
  },
  NewMinStayCri: {
    path: "/min-stay-cri/new",
    permission: getPermissionList("Min Stay Criteria", 1)?.hasPermission,
  },
  EditMinStayCri: {
    path: "/min-stay-cri/edit/:id",
    permission: getPermissionList("Min Stay Criteria", 3)?.hasPermission,
  },
  NewBookingEngine: {
    path: "/booking-engine/new",
    permission: getPermissionList("Booking Engine Criteria", 1)?.hasPermission,
  },
  EditBookingEngine: {
    path: "/booking-engine/edit/:id",
    permission: getPermissionList("Booking Engine Criteria", 3)?.hasPermission,
  },
  NewCouponCode: {
    path: "/coupon-code/new",
    permission: getPermissionList("Coupon Code List", 1)?.hasPermission,
  },
  EditCouponCode: {
    path: "/coupon-code/edit/:id",
    permission: getPermissionList("Coupon Code List", 3)?.hasPermission,
  },
  EditSmsTemplate: {
    path: "/communication-templates-sms/edit/:id",
    permission: getPermissionList("Custom Templates", 3)?.hasPermission,
  },
  EditEmailTemplate: {
    path: "/communication-templates-email/edit/:id",
    permission: getPermissionList("Custom Templates", 3)?.hasPermission,
  },

  Roles: {
    path: "/roles",
    permission: getPermissionList("Roles", 2)?.hasPermission,
  },

  Agreement: {
    path: "/agreement/:BookingId",
    permission: true,
  },
  SuccessAgreement: {
    path: "/agreement/success",
    permission: true,
  },
  SuccessBoking: {
    path: "/direct-booking/:id/success",
    permission: true,
  },

  // Ouner
  OwnerDashBoard: {
    path: "/owner-dashboard",
    permission: true,
  },
  OwnerLogin: {
    path: "/owner-login",
    permission: true,
  },
  OwnerReport: {
    path: "/owner-reports",
    permission: true,
  },
  HotelWiseOwnerReport: {
    path: "/owner-reports/hotel-owner-reports",
    permission: true,
  },
  OwnerIframeReport: {
    path: "/owner-frame-report",
    permission: true,
  },

  Module: {
    path: "/module",
    permission: true,
  },
  NewModule: {
    path: "/module/new",
    permission: true,
  },
  EditModule: {
    path: "/module/edit/:moduleId",
    permission: true,
  },

  Popular_Places: {
    path: "/popular-places",
    permission: getPermissionList("Popular Places", 2)?.hasPermission,
  },
  NewPopular_Places: {
    path: "/popular-places/new",
    permission: getPermissionList("Popular Places", 1)?.hasPermission,
  },
  EditPopular_Places: {
    path: "/popular-places/edit/:placeId",
    permission: getPermissionList("Popular Places", 3)?.hasPermission,
  },

  // Destinations
  Destination: {
    path: "/destination",
    permission: true,
  },
  NewDestination: {
    path: "/destination/new",
    permission: true,
  },
  EditDestination: {
    path: "/destination/edit/:destinationId",
    permission: true,
  },

  Owners: {
    path: "/owners",
    permission: true,
  },
  NewOwners: {
    path: "/owners/new",
    permission: true,
  },
  EditOwners: {
    path: "/owners/edit/:ownersId",
    permission: true,
  },

  // Property

  NewProperty: {
    path: "/property/new",
    permission: true,
  },

  AdminUsers: {
    path: "/admin-users",
    permission: getPermissionList("Admin Users", 2)?.hasPermission,
  },
  AddAdminUsers: {
    path: "/admin-users/new",
    permission: getPermissionList("Admin Users", 1)?.hasPermission,
  },
  EditAdminUsers: {
    path: "/admin-users/edit/:adminUserId",
    permission: getPermissionList("Admin Users", 3)?.hasPermission,
  },
  Shift: {
    path: "/shift",
    permission: getPermissionList("Shift", 2)?.hasPermission,
  },
  NewShift: {
    path: "/shift/new",
    permission: getPermissionList("Shift", 1)?.hasPermission,
  },
  EditShift: {
    path: "/shift/edit/:shiftId",
    permission: getPermissionList("Shift", 3)?.hasPermission,
  },

  CalendarRoom: {
    path: "/calendarRoom",
    permission: true,
  },

  // chat
  Chat: {
    path: "/chat",
    permission: getPermissionList("Chat", 2)?.hasPermission,
  },
  DirectChat: {
    path: "/direct-chat",
    permission: true,
  },
  DirectBooking: {
    path: "/direct-booking/:hotel_id",
    permission: true,
  },
  DirectBookingNew: {
    path: "/direct-bookings",
    permission: true,
  },
  DirectCheckIn: {
    path: "/guest-checkin/:hotel_id",
    permission: true,
  },

  //invoice
  Invoice: {
    path: "/create-invoice/:invoice_id",
    permission: true,
  },
  OtaOverviewNew: {
    path: "/ota-overview-new",
    permission: true,
  },

  //Kitchen Dashboard
  KitchenDashboard: {
    path: "/kitchen-dashboard",
    permission: getPermissionList("Kitchen Dashboard", 2)?.hasPermission,
  },

  //Attraction
  AddAttraction: {
    path: `/add-attraction`,
    permission: getPermissionList("Attractions", 1)?.hasPermission,
  },
  EditAttraction: {
    path: `/edit-attraction/:attraction_id`,
    permission: getPermissionList("Attractions", 3)?.hasPermission,
  },

  //Miscellaneous Charges
  AddMiscellaneousCharges: {
    path: `/add-miscellaneous-charges`,
    permission: getPermissionList("Miscellaneous Charges", 1)?.hasPermission,
  },
  EditMiscellaneousCharges: {
    path: `/edit-miscellaneous-charges/:miscellaneous_charges_id`,
    permission: getPermissionList("Miscellaneous Charges", 3)?.hasPermission,
  },

  //Recurring Charges
  AddRecurringCharges: {
    path: `/add-recurring-charges`,
    permission: getPermissionList("Recurring Charges", 1)?.hasPermission,
  },
  EditRecurringCharges: {
    path: `/edit-recurring-charges/:recurring_charges_id`,
    permission: getPermissionList("Recurring Charges", 3)?.hasPermission,
  },

  //Operating Expenses
  AddOperatingExpenses: {
    path: `/add-operating-expenses`,
    permission: getPermissionList("Operating Expenses", 1)?.hasPermission,
  },
  EditOperatingExpenses: {
    path: `/edit-operating-expenses/:operating_expenses_id`,
    permission: getPermissionList("Operating Expenses", 3)?.hasPermission,
  },

  //Revenue Generation
  RevenueGeneration: {
    path: "/revenue-generation",
    permission: getPermissionList("Revenue Generation", 1)?.hasPermission,
  },
});
