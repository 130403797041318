import React from 'react'


 const DynamicPricing = (props) => {
  return(
    <div>DynamicPricing</div>
   )

 }

 export default DynamicPricing;