import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field,  Formik } from "formik";
import  {  useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchAttractionDetail,
  updateAttraction,

} from "../../../apis/services/AttractionApiService";
import ShowInputError from "../../../shared/components/ShowInputError";
import FormikInput1 from "../../../shared/material-ui-formik/FormikInput1";
import FormikSelect from "../../../shared/material-ui-formik/FormikSelect";

import { Routing } from "../../../shared/constants/routing";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Loader from "../../../layout/Loader";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from "react-places-autocomplete";
import { getLatLng, geocodeByAddress } from "react-places-autocomplete";
import { CreateAttractionValidator } from "../../../shared/validations/AdminValidator";
import moment from "moment";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  // FilePondPluginFileReorder
);

const EditAttraction = () => {
  let { attraction_id } = useParams();


  const navigate = useNavigate();

  const [imagesData, setImagesData] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAttractionDetail();
  }, [attraction_id]);

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [deleteimages, setDeleteImages] = useState([]);
  const [attractionDetail, setAttractionDetail] = useState({
    attraction_name: "",
    description: "",
    link: "",
    type: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    lat: "",
    long: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(5, "days").format("YYYY-MM-DD"),
  });


  const initialState = {
    attraction_name: attractionDetail?.attraction_name,
    description: attractionDetail?.description,
    link: attractionDetail?.link,
    type: attractionDetail?.type,
    address: attractionDetail?.address,
    city: attractionDetail?.city,
    state: attractionDetail?.state,
    country: attractionDetail?.country,
    postal_code: attractionDetail?.postal_code,
    lat: attractionDetail?.lat,
    long: attractionDetail?.long,
    startDate: attractionDetail?.startDate,
    endDate: attractionDetail?.endDate,
  };

  useEffect(() => {
    getAttractionDetail();
  }, [attraction_id]);

  const getAttractionDetail = async () => {
    const result = await fetchAttractionDetail(attraction_id);
    if (result?.status === 200) {
      setAttractionDetail({
        attraction_name: result?.data?.name,
        description: result?.data?.description,
        link: result?.data?.link,
        type: result?.data?.attraction_type,
        address: result?.data?.address,
        city: result?.data?.city,
        state: result?.data?.state,
        country: result?.data?.country,
        postal_code: result?.data?.postal_code,
        lat: result?.data?.latitude,
        long: result?.data?.longitude,
        startDate: result?.data?.start_date,
        endDate: result?.data?.end_date,
      });
      setImagesData(result?.data?.images);
  
      // Update initialState directly here

    } else {
      setAttractionDetail({
        attraction_name: "",
        description: "",
        link: "",
        type: 0,
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        lat: "",
        long: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(5, "days").format("YYYY-MM-DD"),
      });
    }
  };


  const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
  const handleProcessFile = (error, file) => {
    if (error) {
      console.error("File is not valid:", error);
      toast.error(t("File Is Not Valid"));
      // Optionally, you can show an error message to the user
      // setError('Invalid file type. Please upload a PNG, JPEG, or JPG file.');
    } else {
      // File is valid, proceed with further logic
      // setError(null);
    }
  };


 
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    // eslint-disable-next-line no-console
  
    let bodyFormData = new FormData();
   
   
    bodyFormData.append("name", values.attraction_name || "");
    bodyFormData.append("description", values.description || "");
    bodyFormData.append("link", values.link || "");
    bodyFormData.append("address", values.address || "");
    bodyFormData.append("latitude", values.lat || "");
    bodyFormData.append("longitude", values.long || "");
    bodyFormData.append("attraction_type", values.type);
    bodyFormData.append("start_date", values.startDate);
    bodyFormData.append("city", values?.city || "");
    bodyFormData.append("end_date", values.endDate);
    bodyFormData.append("country", values?.country || "");
    bodyFormData.append("state", values.state || "");
    bodyFormData.append("postal_code", values?.postal_code || "");

    images?.forEach((item) => {
      bodyFormData.append("images", item);
    });

    deleteimages.forEach((item, key) =>
      bodyFormData.append(`deleted_images[${key}]`, item)
    );

    const result = await updateAttraction(bodyFormData, attraction_id);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      setImages([]);
      setFiles([]);
      navigate(Routing.Setting + "?tab=14");
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };

  useEffect(() => {
    let img = [];
    let delimg = [];
    let deleID = [];
    if (files !== null) {
      for (var j in files) {
        if (files[j]?.file?.webkitRelativePath == "") {
          img.push(files[j].file);
        } else {
          for (var j in files) {
            delimg.push({
              id: 0,
              // imges: imagesData[j]?.image.split("/")[4],
              image: files[j]?.file?.name,
            });
          }
        }
      }
    }
    setImages(img);
    const results = imagesData.filter(
      ({ image: id1 }) =>
        !delimg.some(({ image: id2 }) => id2 === id1.split("/")[4])
    );
    if (results !== null) {
      for (var j in results) {
        deleID.push(parseInt(results[j]?.id));
      }
    }
    setDeleteImages(deleID);
  }, [files]);

  // console.log(images, 230);

  useEffect(() => {
    let img = [];
    let imgs = [];
    if (imagesData !== null) {
      for (var j in imagesData) {
        img.push({
          id: imagesData[j]?.id,
          image: imagesData[j]?.image,
        });
      }
      for (var j in imagesData) {
        imgs.push(imagesData[j]?.image);
      }
    }
    setImages(img);
    setFiles(imgs);
  }, [imagesData]);

  const handleSelectAddress = async (selectedAddress, setFieldValue) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      console.log("results", results);
      const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);
      // Extract city, state, country, and postal code from address components
      results[0].address_components.forEach((component) => {
        // console.log(component, 171);
        console.log("results", component);

        if (component.types.includes("locality")) {
          setFieldValue("city", component.long_name);
        } else if (component.types.includes("administrative_area_level_1")) {
          setFieldValue("state", component.long_name);
        } else if (component.types.includes("country")) {
          setFieldValue("country", component.long_name);
        } else if (component.types.includes("postal_code")) {
          setFieldValue("postal_code", component.long_name);
        }
      });
      setFieldValue("address", selectedAddress);
      setFieldValue("lat", latLng.lat);
      setFieldValue("long", latLng.lng);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const handlePostalSearch = async (e, setFieldValue) => {
    setFieldValue("postal_code", e.target.value);
    if (e.target.value?.length < 4) {
      return;
    } else {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            e.target.value
          )}&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const result = data.results[0];

          // Extract city, state, and country from address components
          result.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              setFieldValue("city", component.long_name);
              // setCity(component.long_name);
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              setFieldValue("state", component.long_name);
              // setState(component.long_name);
            } else if (component.types.includes("country")) {
              setFieldValue("country", component.long_name);
              // setCountry(component.long_name);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching geocoding data:", error);
      }
    }
  };


  return (
    
      <div className="main-layout-background">
        {loading && <Loader />}

        <div className=" pl-2 pr-2 pb-2 mt-3">
          <div
            className="d-flex mt-2rem mb-2 pb-2"
            style={{ borderBottom: "2px solid", alignItems: "baseline" }}
          >
            <h1 className="pagename-heading ml-0">{t("Attractions")}</h1>
            <p className="">
              {t("Home")} /{t("Edit Attraction")}
            </p>
          </div>
        </div>
        <div className="px-2">
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={() => CreateAttractionValidator(t)}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Name")}
                      name="attraction_name"
                      id="attraction_name"
                      // defaultOption
                      placeHolder={t("Enter Name")}
                      component={FormikInput1}
                    />
                    <ErrorMessage
                      name="attraction_name"
                      component={ShowInputError}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="font-600"
                      style={{
                        marginBottom: "11px",
                        color: "rgb(30, 30, 30)",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t("Image of Attraction Type")}
                    </Typography>
                    <FilePond
                      accepted-file-types={"png/jpg"}
                      files={files}
                      allowReorder={true}
                      allowMultiple={true}
                      onupdatefiles={setFiles}
                      acceptedFileTypes={acceptedFileTypes}
                      allowFileValidateType
                      onprocessfile={(error, file) =>
                        handleProcessFile(error, file)
                      }
                      labelIdle={`${t(
                        "Drag & Drop your files or"
                      )} <span class="filepond--label-action">${t(
                        "Browse"
                      )}</span> ${t("(only Upload PNG/JPG/JPEG file)")}`}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    {/* Default Accupancy Changes to Min Occupancy */}
                    <Field
                      label={t("Description")}
                      name="description"
                      placeHolder={t("Enter description")}
                      id="description"
                      component={FormikInput1}
                    />
                    <ErrorMessage
                      name="description"
                      component={ShowInputError}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Link")}
                      name="link"
                      placeHolder={t("Enter link")}
                      id="link"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="link" component={ShowInputError} />
                  </Grid>
                  {/*  */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="font-600"
                      style={{
                        marginBottom: "11px",
                        color: "rgb(30, 30, 30)",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 500,
                      }}
                    >
                      {t("Search By Google")}
                    </Typography>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={(e) =>
                        handleSelectAddress(e, props?.setFieldValue)
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: t("Search Places ..."),
                              className: "location-search-input",
                            })}
                            style={{
                              padding: "14px",
                              border: "1px solid #B4ADAD",
                              color: "#255480",
                              borderRadius: "6px",
                              width: "100%",
                              position: "relative",
                            }}
                          />
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: "absolute",
                              outline: "none",
                              zIndex: "1",
                              listStyle: "none",
                              padding: "0",
                              margin: "10px 0 10px -1px",
                              boxShadow: "1px 2px 18px rgba(0,0,0,0.25)",
                              backgroundColor: "white",
                              width: "100%",
                              maxHeight: "220px",
                              overflowY: "scroll",
                              borderRadius: "7px",
                            }}
                          >
                            {loading && (
                              <div
                                style={{
                                  padding: "5px",
                                  borderBottom: "1px solid #fafafa",
                                }}
                              >
                                {t("Loading...")}
                              </div>
                            )}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: "5px",
                                    borderBottom: "1px solid #fafafa",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "5px",
                                    borderBottom: "1px solid #fafafa",
                                  };
                              return (
                                <div
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Address")}
                      name="address"
                      placeHolder={t("Enter the valid address")}
                      id="address"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="address" component={ShowInputError} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Country")}
                      placeHolder={t("Enter Country")}
                      name="country"
                      id="country"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="country" component={ShowInputError} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("State")}
                      placeHolder={t("Enter State")}
                      name="state"
                      id="state"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="state" component={ShowInputError} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("City")}
                      placeHolder={t("Enter City")}
                      name="city"
                      id="city"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="city" component={ShowInputError} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Postal Code")}
                      placeHolder={t("Enter Postal Code")}
                      name="postal_code"
                      onChange={(e) =>
                        handlePostalSearch(e, props?.setFieldValue)
                      }
                      id="postal_code"
                      component={FormikInput1}
                    />
                    <ErrorMessage
                      name="postal_code"
                      component={ShowInputError}
                    />
                  </Grid>

                  {/*  */}
                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Latitude")}
                      placeHolder={t("Enter latitude")}
                      name="lat"
                      id="lat"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="lat" component={ShowInputError} />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      label={t("Longitude")}
                      name="long"
                      placeHolder={t("Enter longitude ")}
                      id="long"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="long" component={ShowInputError} />
                  </Grid>

                  <Grid item md={6} xs={12} sx={{ paddingTop: "35px" }}>
                    <Field
                      label={t("Start Date")}
                      name="startDate"
                      id="startDate"
                      type="date"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="startDate" component={ShowInputError} />
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ paddingTop: "35px" }}>
                    <Field
                      label={t("End Date")}
                      name="endDate"
                      id="endDate"
                      type="date"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="endDate" component={ShowInputError} />
                  </Grid>

                  <Grid item md={6} xs={12} sx={{ paddingTop: "35px" }}>
                    <Typography
                      id="font-600"
                      style={{
                        marginBottom: "11px",
                        color: "rgb(30, 30, 30)",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t("Attraction Type")}
                    </Typography>
                    <Field
                      label={t("Attraction Type")}
                      name="type"
                      placeHolder={t("Select Attraction Type")}
                      options={[
                        { title: "Select Attraction Type", value: 0 },
                        { title: "Local", value: 1 },
                        { title: "Near By", value: 2 },
                      ]}
                      component={FormikSelect}
                    />

                    <ErrorMessage name="type" component={ShowInputError} />
                  </Grid>
                </Grid>

                {/* room_type_short_code */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "20px",
                    marginBottom: "20px",
                    borderTop: "2px solid",
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    <Button
                      className="mt-1 mr-1 comman-cancel-button"
                      onClick={() => navigate(Routing.Setting + "?tab=14")}
                      size="large"
                      variant="contained"
                      disableElevation
                      color="primary"
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      className="mt-1 comman-button"
                      size="large"
                      variant="contained"
                      disableElevation
                      type="submit"
                      color="primary"
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      </div>
    
  );
};


export default EditAttraction;
