import axios from "axios";
import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";
import { BASE_URL } from "../../config/Urls";

export const createHotel = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createHotelForSignup = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_HOTEL_FOR_SIGNUP,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateHotel = async (data: object, hotelId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteHotelApiCall = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const fetchHotelList = async (pageNo, itemsPerPage, searchText) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.HOTEL_RESOURCE_ROUTE +
        `?page_no=${pageNo}&search_text=${searchText}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDestinationForSelect = async () => {
  try {
    const instances = axios.create({
      baseURL: BASE_URL,
      timeout: 1000,
      headers: {'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJzdXBlcmFkbWluQGhpdmUuY29tIiwiaWF0IjoxNzM2MzEwNzg2LCJleHAiOjE3Njc4NDY3ODZ9.gLirAu08uYUCswaV8jixEAi_Kug-jRviD70HYFHCrAs`}
    });

    const result = await instances({
    // const result = await instance({
      url: ApiEndPoints.DESTINATION_LIST_FOR_SELECT,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHotelDetail = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

//GET_DIRECT_HOTEL_DETAIL

export const fetchDirectHotelDetail = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.GET_DIRECT_HOTEL_DETAIL+ `${hotelId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};