export const ApiEndPoints = {
  LOGIN_API_CALL: "adminusers/login",
  LOGIN_OWNER_API: "owner-users/login",
  RESET_ADMIN_USER: "/adminusers/request-reset",
  RESET_PASSWORD: "/adminusers/reset-password",
  SEND_OTP_FOR_SIGNUP: "/user/send-otp-for-hotel-signup",
  // CREATE_HOTEL: "hotels",
  // UPDATE_HOTEL: "hotels",
  // GET_HOTEL_LIST: "hotels",
  // GET_HOTEL_DETAIL: "hotels",
  ADD_USER: "user/addUser",
  CHANGE_BOOKING_USER: "booking/change-booking-user",
  CHECK_USER_EXIST: "user/check-user-exist",
  USER_AUTOCOMPLETE: "user/searchUser",
  POS_USER_AUTOCOMPLETE: "pos-sales/search-visitors",
  GROUP_AUTOCOMPLETE: "group-masters/search",
  HOTEL_RESOURCE_ROUTE: "hotels",
  CREATE_HOTEL_FOR_SIGNUP: "hotels/create-hotel-from-signup",
  HOTEL_UPDATE_EXTRA_DETAIL: "hotels/update-hotel-extra-details",
  UPDATE_DIRECT_BOOKING_ENGINE: "hotels/update-booking-engine-status",
  UPDATE_SMART_LOCK: "hotels/update-smart-lock-status",
  CHANGE_POS_TYPE: "hotels/change-pos-type",
  UPDATE_DIRECT_BOOKING_MIN_STAY: "hotels/update-min-stay-length",
  ROOM_CATEGORY_RESOURCE_ROUTE: "roomcategories",
  ROOM_CATEGORY_SELECT_LIST: "roomcategories/get_category_types",
  ROOM_MASTER_TYPES: "rooms/get_master_types",
  MATRESS_LIST: "rooms/get_mattress_lists",
  FACILITY_SUB_FACILITY_LIST: "rooms/get_facility_subfacility_lists",
  SUB_FACILITY_LIST: "rooms/get_sub_facility_lists",
  BED_LIST: "rooms/get_bed_lists",
  CREATE_MULTIPLE_ROOMS: "rooms/createMultipleRooms",
  GET_USER_CARD_DETAIL: "user/get-cards",
  GET_RATES_PER_PARTICULER_DATE: "room-rates/get-rates-for-particular-date",
  ADD_USER_CARD_DETAIL: "user/add-card",
  VERIFY_USER_CARD_DETAIL: 'booking/verify-card',
  CHANGE_PRIMARY_CARD: 'booking/change-credit-card',
  SPLIT_PAY_NOW: "booking/split-pay-now",
  SAVE_DEPOSIT_CARD: "booking/save-advance-deposit-card",
  UPDATE_PAYMENT_TYPE: "booking/change-booking-payment-type",

  //notifications

  GET_NOTIFICATION_COUNT: "notifications/count",
  GET_NOTIFICATION_LIST: "notifications",
  DISCARD_NOTIFICATION: "notifications/mark-as-read",

  //advance booking card authorize and capture

  PAYMENT_CARD_AUTHORIZATION: "booking/advance-booking-card-authorization",
  PAYMENT_CARD_CAPTURE: "booking/advance-booking-card-direct-capture",
  PARTIAL_CARD_CAPTURE: "booking/partial-card-capture",
  CHECK_AMOUNT_CAPTURE_AND_AUTHORISE: "booking/get-booking-total-authorize-and-capture-amount",


  //save invoice
  SAVE_BOOKING_INVOICE: 'booking/save-booking-invoice',
  // Edit mobile

  EDIT_NAME: "user/update-name",
  EDIT_MOBILE: "user/update-mobile-number",
  OTP_MOBILE: "user/send-otp",
  UPDATE_USER_BOOKING: "user/change-user-in-booking",
  ADD_SECONDARY_USER: "user/update-secondary-user-in-booking",

  //Edit Email

  EDIT_EMAIL: "user/update-email",

  //edit Folio Comment

  Edit_FOLIO_COMMENT: "booking/update-folio-comment",

  //get Hotel details withot token

  GET_DIRECT_HOTEL_DETAIL: "hotels/hotel-detail-for-direct-booking?hotel_id=",

  //send sms
  SEND_SMS: "email-sms/send-manual-sms",

  //send Email
  SEND_EMAIL: "email-sms/send-manual-email",

  //send Agrement
  SEND_AGREMENT: "email-sms/send-agreement-link-sms",
  SEND_AGREMENT_EMAIL: "email-sms/send-agreement-link-email",

  //checkin Info
  SEND_CHECK_IN_INFO: "email-sms/send-check-in-info-sms",
  SEND_CHECK_IN_INFO_EMAIL: "email-sms/send-check-in-info-email",

  // add vehicle Details
  ADD_VEHICLE_DETAIL: "booking-common/add-booking-vehicle-info",

  //send Folio Receipt

  SEND_FOLIO_RECEIPT: "email-sms/send-invoice-link-sms",
  SEND_FOLIO_RECEIPT_EMAIL: "email-sms/send-invoice-link-email",


  ADD_GEUST: 'user/addUserToRoom',

  // otas 
  OTA_RESOURCE_ROUTE: "connected-otas",
  GET_OTA_LOGS: "connected-otas/ota-logs",
  OTA_LIST_FOR_SELECT: "connected-otas/get-otas-for-select",
  // GET_OTA_DETAILS: "",
  // CREATE_SINGLE_OTA: "",

  //rooms
  ROOMS_RESOURCE_ROUTE: "rooms",
  HOUSEKEEPING_RESOURCE_ROUTE: "housekeepings",
  HOUSEKEEPER_LIST: "housekeepings/get-housekeeper-list-for-select",
  AVAILABLE_ROOM_BY_CAT: "rooms/get-available-rooms-by-category",
  ASSIGN_ROOM_HOUSEKEEPER: "housekeepings/assign-room-to-housekeeper",
  SWITCH_ASSIGN_ROOM_HOUSEKEEPER: "housekeepings/change-assign-room-to-housekeeper",

  
  //Housekeeper Api
  GET_HOUSEKEEPER_ASSIGNED_ROOM: "housekeepings/get-housekeeper-assigned-rooms",
  GET_HOUSEKEEPER_ASSIGNED_ROOM_DETAIL: "housekeepings/get-housekeeping-detail",
  HOUSEKEEPER_CHANGE_ROOM_STATUS:"housekeepings/housekeeper-change-status",
  HOUSEKEEPER_UPLOAD_IMAGES:"housekeepings/upload-housekeeping-images",

  AVAILABLE_ROOM_BY_CAT_AND_ROOM: "rooms/checkin-get-available-rooms-by-category-including-existing",

  ROOM_RATES_RESOURCE_ROUTE: "room-rates",
  GET_SUGGESTED_RATE_OVERVIEW: "suggested-hotels/suggested-rate-overview",
  GET_SUGGESTED_HOTEL_LIST: "suggested-hotels/get-competitor-hotel-list",
  ADD_COMPETITOR_RATES: "crons/add-competitor-rates",
  GET_COMPETITOR_HOTEL_BY_SEARCH: "suggested-hotels/search-suggested-hotels",
  GET_COMPETITOR_HOTEL_RATE: "suggested-hotels/get-competitor-hotel-rates",
  ADD_SUGGESTED_HOTEL: "suggested-hotels/add-competitor-hotel",
  DELETE_SUGGESTED_HOTEL: "suggested-hotels/delete-competitor-hotel",
  GET_ROOM_RATES_TYPE_FOR_SELECT: "room-rates/get-rates-types-for-select",
  GET_ROOM_LIST_FOR_SELECT: "rooms/get-room-list-for-select",
  ACCOMMODATION_LIST: "room-rates/get_accommodation_types",
  DESTINATION_LIST_FOR_SELECT: "destinations/get_list_for_select",
  APPLY_UPDATE_DIRECT_BOOKING_RATE: "room-rates/apply-suggested-rate-to-direct-booking",

  GET_ROOM_RATES_DISCOUNT_RATE: "room-rates/get-discounted-room-rate-list",
  CREATE_ROOM_RATES_DISCOUNT_RATE: "room-rates/create-discounted-rate",

  SEASONAL_ROOM_RATES_RESOURCE_ROUTE: "seasonal-room-rates",
  ROOM_RESTRICTION_RESOURCE_ROUTE: "room-restrictions",
  UPDATE_RATES_PERTICULAR_DATE: "room-rates/update-rates-for-particular-date",
  GET_CANCELLATION_POLICY: "cancellation-policies",
  GET_CANCELLATION_POLICY_SELECT: "cancellation-policies/get-policy-list-for-select",

  //bookings
  CREATE_BOOKING: "booking/hotelBooking",
  BOOKING_CHECK_IN_CHECK_OUT: "booking/getCheckInCheckOutList",
  BOOKING_CHECK_IN: "booking/checkIn",
  BOOKING_CHECK_OUT: "booking/checkOut",
  GET_RATE_FOR_BOOKING: "roomcategories/get_rates_for_booking",
  GET_BOOKING_DETAILS: "booking/bookingDetail",
  GET_BOOKING_FOLIO_DETAILS: "booking/booking-folio-detail",
  GUEST_USER_LIST_REPORT: "user/list",
  CREATE_GROUP_BOOKING: "booking/create-folio-booking",
  CHANGE_BOOKING_ROOM: "booking/change-booking-room",
  GET_ALL_PINPAD_DEVICE: "hotels/get-all-pinpad-devices",
  GET_RATE_FOR_BOOKING_OVERVIEW: "room-rates/calender-overview",
  GET_CALENDER_AVAILABILITY_OVERVIEW: "room-rates/calender-availability-overview",
  OTA_AVAILABILITY_INVENTORY: "content/bulk-rates-and-inventory-logs",
  CHANGE_BOOKING_ROOM_UPDATE: "booking/change-upgrade-booking-room",
  MAKE_ROOM_UNASSIGNED: "booking/make-room-unassigned",
  GET_OTA_RATES_OVERVIEW: "connected-otas/rate-overview",
  SEND_ALL_NIGHT_REPORT_EMAIL: "email-sms/send-night-report-email",

  //direct booking coupen

  VERIFY_COUPEN_CODE: "coupons/check-coupon-valid",
  GET_COUPON_CODE: "coupons",

  //POS
  POS_SALE_ADD_USER: "pos-sales/add-visitor",
  PRODUCT_TYPES_RESOURCE_ROUTE: "product-types",
  PRODUCTS_RESOURCE_ROUTE: "products",
  POS_ADD_ON: "pos-addons",
  VENDORS_RESOURCE_ROUTE: "vendors",
  VENDOR_LIST_FOR_SELECT: "vendors/get-vendors-for-select",
  PRODUCT_TYPES_SELECT_RESOURCE_ROUTE: "product-types/get_types_for_select",
  PRODUCT_BY_TYPES_RESOURCE_ROUTE: "products/get-products-by-types",
  POS_ADD_ON_BY_TYPES_RESOURCE_ROUTE: "pos-addons/get-addons-by-types",
  POS_SALES_SAVE_RESOURCE_ROUTE: "pos-sales",
  POS_SALES_SAVE_VISITOR_RESOURCE_ROUTE: "pos-sales/visitor-sale",
  GET_VISITOR_SALES_LIST: "pos-sales/get-visitors-sales",
  GET_VISITOR_SALES_DETAIL: "pos-sales/get-visitors-sales-detail",
  HOUSEKEEPING_UPDATE_ROOM_STATUS: "housekeepings/update-room-status",
  GET_INHOUSE_USER_LIST: "pos-sales/get-in-house-users",
  GET_TABLE_LIST: "pos-tables",
  GET_KITCHEN_TYPE_LIST: "kitchen-sections",
  GET_KITCHEN_TYPE_SELECT: "kitchen-sections/get-section-list-for-select",
  GET_TABLE_LIST_FOR_SELECT: "pos-tables/get-table-list-for-select",
  GET_VISITOR_KITCHEN_DASHBOARD_LIST: "pos-sales/get-visitors-kitchen-dashboard-list",
  GET_INHOUSE_TRADITIONAL_KITCHEN_DASHBOARD_LIST: "pos-sales/get-inhouse-traditional-kitchen-dashboard-list",
  GET_VISITOR_TRADITIONAL_KITCHEN_DASHBOARD_LIST: "pos-sales/get-visitors-traditional-kitchen-dashboard-list",
  VISITOR_TRADITIONAL_KITCHEN_PRODUCT_UPDATE: "pos-sales/visitor-traditional-kitchen-product-update",
  INHOUSE_TRADITIONAL_KITCHEN_PRODUCT_UPDATE: "pos-sales/inhouse-traditional-kitchen-product-update",
  VISITOR_KITCHEN_MARK_AS_DONE: "pos-sales/visitor-kitchen-mark-as-done",
  INHOUSE_KITCHEN_MARK_AS_DONE: "pos-sales/inhouse-kitchen-mark-as-done",
  GET_INHOUSE_KITCHEN_DASHBOARD_LIST: "pos-sales/get-inhouse-kitchen-dashboard-list",
  GET_POS_CLIENT_SECRET: "pos-sales/pos-create-payment-intent",
  VISITOR_TRADITIONAL_SALE: "pos-sales/visitor-traditional-sale",
  INHOUSE_TRADITIONAL_SALE: "pos-sales/inhouse-traditional-sale",
  EDIT_VISITOR_TRADITIONAL_SALE: "pos-sales/edit-visitor-traditional-sale",
  EDIT_INHOUSE_TRADITIONAL_SALE: "pos-sales/edit-visitor-traditional-sale",
  GET_VISITOR_TRADITIONAL_SALE: "pos-sales/get-visitor-traditional-sale",
  GET_INHOUSE_TRADITIONAL_SALE: "pos-sales/get-inhouse-traditional-sale",
  COMPLETE_VISITOR_TRADITIONAL_SALE: "pos-sales/visitor-traditional-sale-complete",
  COMPLETE_INHOUSE_TRADITIONAL_SALE: "pos-sales/inhouse-traditional-sale-complete",
  VISITOR_TRADITIONAL_SALE_PRODUCT_CANCEL: "pos-sales/visitor-traditional-sale-product-cancel",
  INHOUSE_TRADITIONAL_SALE_PRODUCT_CANCEL: "pos-sales/inhouse-traditional-sale-product-cancel",
  TRADITIONAL_SALE_PRODUCT_ADD_ADDON: "pos-sales/visitor-traditional-sale-add-addon",
  INHOUSE_SALE_PRODUCT_ADD_ADDON: "pos-sales/inhouse-traditional-sale-add-addon",
  TRADITIONAL_SALE_PRODUCT_ADD_ADDON_CANCEL: "pos-sales/visitor-traditional-sale-addon-cancel",
  GET_POS_TABLE_STATUS_LIST: "pos-tables/get-tables-status-count",
  STOCK_ADJUSTMENT: "stock-adjustments",

  //po order
  PURCHASE_ORDER: "purchase-orders",
  PURCHASE_ORDER_MATERIAL_RECEIVED: "purchase-orders/material-received",
  

  //pos reports
  POS_SALES_SUMMARY_REPORT: "pos-reports/sales-summary-report",
  POS_KITCHEN_WISE_REPORT: "pos-reports/kitchen-wise-report",
  POS_CATEGORY_WISE_REPORT: "pos-reports/category-wise-report",
  POS_CUSTOMER_INHOUSE_REPORT: "pos-reports/customer-inhouse-report",
  POS_CUSTOMER_VISITOR_REPORT: "pos-reports/customer-visitor-report",

  //reports
  ARRIVAL_REPORT: "reports/arrival-list",
  CHECKIN_REPORT: "reports/checkin-list",
  DEPARTURE_REPORT: "reports/departure-list",
  CHECKOUT_REPORT: "reports/checkout-list",
  CANCELLATION_REPORT: "reports/cancellation-list",
  VACANT_ROOM_REPORT: "reports/vacant-room-list",
  IN_HOUSE_REPORT: "reports/in-house-list",
  IN_HOUSE_REPORT_BY_DATE: "reports/in-house-list-by-date",
  NO_SHOW_REPORT: "reports/no-show-list",
  GUESTS_REPORT: "reports/guest-list",
  HOTAL_STATISTICS_REPORT: "reports/hotel-statistics",
  REVENUE_BY_ROOM_TYPE_REPORT: "reports/revenue-by-room-type",
  AVERAGE_DAILY_RATE_LIST_REPORT: "reports/average-daily-rate-list",
  GET_DASHBOARD_STATS: "booking/get-dashboard-stats",
  GET_POS_DASHBOARD_STATS: "pos-reports/dashboard-selling-snapshot",
  GET_FLASH_REPORT: "reports/flash-report",
  GET_FLASH_REVENUE_REPORT: "reports/flash-report-revenue-breakdown",
  FORECAST_REPORT: "reports/forecast-report",
  GET_REVENUE_BY_SOURCE: "reports/revenue-by-source",


  GET_BOOKING_PENDING_AMOUNT: "booking/get-booking-total-capture-amount",


  //Owner Dashboard States
  GET_OWNER_DASHBOARD_STATS: "owners/owner-dashboard-performance-snapshot",
  GET_OWNER_PROPERTY_LIST: "owners/owner-dashboard-property-list-stats",
  GET_OWNER_REVPAR_GRAPH: "owners/get-revpar-graph-data",
  GET_OWNER_LOGIN_DETAILS: "owners/hotel-login-details",

  //dashboardreservationsstate
  GET_DASHBORD_RESERVATIONS_STATE: "booking/get-dashboard-reservations-stats",

  CHANGE_ROOM_STATUS: "rooms/change-room-status",

  // Company
  COMPANY_RESOURCE_ROUTE: "companies",

  //Group information
  GROUP_INFORMATION: "group-masters",

  // TAX
  TAXES: "taxes",
  GET_TAXES: "taxes/get-taxes-list-for-select",
  DELETE_TAXES: "product-types",

  COMMON: "common",

  GET_RESERVATIONS: "booking/reservations",
  GET_NON_ASSIGNED_ROOMS: "reports/get-non-assigned-room-list",
  CHANGE_BOOKING_STATUS: "booking/change-booking-status",
  ADD_REMARK: "booking-common/add-remark",
  GET_BLOCK_ROOM_LIST: "room-restrictions/between-dates",
  DELETE_BOOKING: "booking/",

  VOID_TRANSACTION: "booking/void-transaction",
  REFUND_TRANSACTION: "booking/token-refund-transaction",
  REFUND_CASH_TRANSACTION: "booking/cash-refund-transaction",
  VISITOR_REFUND_TRANSACTION: "booking/visitor-pos-token-refund-transaction",
  VISITOR_REFUND_CASH_TRANSACTION: "booking/visitor-pos-cash-refund-transaction",

  ADVANCE_DEPOSIT_TRANSACTION: "booking/advance-deposit-card-authorization",
  ADVANCE_DEPOSIT_CAPTURE_TRANSACTION: "booking/advance-deposit-card-capture",


  // Night Reports
  GET_HOTEL_JOURNAL_SUMMARY: "reports/hotel-journal-summary-report",
  GET_SHIFT_RECONCILIATION_CLOSEOUT: "reports/shift-reconciliation-closeout",
  GET_FINAL_TRANSACTION_CLOSEOUT_REPORT:
    "reports/final-transaction-closeout-report",
  GET_TAX_EXEMPT_REPORT_STATS: "reports/tax-exempt-report-stats",
  GET_NIGHT_AUDIT_NO_SHOW_LIST: "reports/night-audit-no-show-list",
  GET_NIGHT_AUDIT_HOTEL_STATASTICS: "reports/night-audit-hotel-statastics",
  GET_LEDGER_SUMMARY: "reports/ledger-summary",
  LEDGER_SUMMARY_REPORT: "reports/ledger-summary-receivables",

  GET_PRE_PAID_RESERVATIONS: "reports/pre-paid-reservations",
  GET_IN_HOUSE_LIST_NEW: "reports/in-house-list-new",
  GET_ACTIVITY_LOG_NIGHT_REPORT: "reports/get-activity-logs",
  GET_Z_OUT_REPORT: "reports/z-out-report",

  //Channel Menagement
  GET_CHANNAL_MANAGERS: "channel-managers/channels",
  GET_HOTEL_CHANNAL_LIST: "channel-managers/get-channel-managers-list",
  GET_ROOM_RATES: "channel-managers/room-rates",
  GET_PRICE_MODEL: "channel-managers/price-model",
  GET_AVAILABILITY: "channel-managers/availability",
  GET_IFRAME_TOKEN: "channel-managers/get-iframe-token",
  GET_ROOM_TYPE: "content/roomTypeListing",
  GET_HOTEL_DETAILS: "hotels/hotelDetailsByPropertyID/",
  CREATE_ROOM_TYPE: "content/createUpdateRoomType",
  GET_RATE_PLAN: "content/ratePlanListing",
  CREATE_RATE_PLAN: "content/createUpdateRatePlan",
  CREATE_RATES_AND_AVAILABILITY: "content/pushRatesAndAvailability",
  ACTIVATE_RATE_PLAN: "content/activateRatePlan",
  DEACTIVATE_RATE_PLAN: "content/deactivateRatePlan",
  DELETE_RATE_PLAN: "content/deleteRatePlan",
  ACTIVATE_ROOM_TYPE: "content/activateRoomType",
  DEACTIVATE_ROOM_TYPE: "content/deactivateRoomType",
  DELETE_ROOM_TYPE: "content/deleteRoomType",
  GET_ROOM_ID_DATA: "rooms/get-room-list-for-select",
  GET_ROOM_CATEGORY_DATA: "roomcategories/get_category_types",
  GET_ROOM_PLAN_ID_DATA: "room-rates/get-rates-types-for-select",
  CREATE_RATES_ACCORDING_OTA: "content/manage-rate-on-different-ota",
  REMOVE_RATES_ACCORDING_OTA: "content/remove-rate-rule-on-different-ota",
  CREATE_AVAILIBILITY_ACCORDING_OTA: "content/manage-availability-on-different-ota",
  REMOVE_AVAILIBILITY_ACCORDING_OTA: "content/remove-availability-rule-on-different-ota",


  // Device Menagement
  CREATE_LOCK: "device-management/add-lock",
  DELETE_LOCK: "device-management",
  GET_LOCK_LIST: "device-management/get-lock-list",
  GET_GETEWAY_LIST: "device-management/get-gateway-list",

  GET_LOCK_DETAIL_FROM_TTLOCK: "device-management/get-lock-detail-from-ttlock",
  GET_REFRESH_PASSCODE: "device-management/refresh-passcode",
  GET_RESET_PASSCODE: "device-management/reset-passcode",
  GET_LOCK_STATUS_FROM_TTLOCK: "device-management/get-lock-status-from-ttlock",
  GET_LOCK_RECORDS_LIST: "device-management/get-lock-records-list",
  SET_AUTO_LOCK_TIME: "device-management/set-auto-lock-time",
  CHANGE_LOCK_NAME: "device-management/change-lock-name",
  UNLOCK: "device-management/unlock",
  LOCK: "device-management/lock",
  UPDATE_LOCK_PASSCODE: "device-management/update-lock-passcode",
  UPDATE_LOCK_PASSCODE_IN_DB: "device-management/update-lock-passcode",
  GET_LOCK_DETAIL_FROM_DB: "device-management/get-lock-detail",

  //Add-On
  ADD_ON: 'addons',

  // Cleaning Checklist
  GET_CLEANING_CHECKLIST: "hotel-cleaning-checklist",
  GET_CLEANING_CHECKLIST_SELECT: "hotel-cleaning-checklist/get-check-list-select",

  // Occ Criteria
  GET_OCC_CRI: "occ-rate-criteria",
  GET_SPE_CRI: "special-date-rate-criteria",
  GET_MIN_STAY_CRI: "min-stay-criteria",
  GET_MIN_STAY_BY_DATE: "min-stay-criteria/get-criteria-between-dates",
  GET_BOOKING_ENGI_BY_DATE: "booking-engine-criteria/get-criteria-between-dates",
  GET_BOOKING_ENGI_CRI: "booking-engine-criteria",

  GET_PROPERTY_MAINTENANCE: "/property-maintenance",
  UPDATE_PROPERTY_MAINTENANCE_STATUS: "/property-maintenance/update-status",

  // Get communication templates
  GET_EMAIL_TEMPLATES: "custom-templates/get-email-templates-list",
  GET_SMS_TEMPLATES: "custom-templates/get-sms-templates-list",
  GET_MARKETING_EMAIL_TEMPLATES: "custom-templates/get-marketing-email-templates-list",
  GET_MARKETING_SMS_TEMPLATES: "custom-templates/get-marketing-sms-templates-list",
  GET_EMAIL_TEMPLATES_DETAIL: "custom-templates/get-email-templates-detail",
  GET_SMS_TEMPLATES_DETAIL: "custom-templates/get-sms-templates-detail",
  UPDATE_EMAIL_TEMPLATES_DETAIL: "custom-templates/update-email-templates-detail",
  UPDATE_SMS_TEMPLATES_DETAIL: "custom-templates/update-sms-templates-detail",
  TEST_EMAIL_TEMPLATES_DETAIL: "custom-templates/send-test-email-templates",
  TEST_SMS_TEMPLATES_DETAIL: "custom-templates/send-test-sms-templates",
  SEND_MARKETING_EMAIL_SMS: "custom-templates/send-marketing-email-sms",
  GET_AGREEMENT_TEMPLATE: "custom-templates/get-agreement-content",
  UPDATE_AGREEMENT_TEMPLATES_DETAIL: "custom-templates/update-agreement-templates-detail",



  // DELETE_OCC_CRI: "occ-rate-criteria",

  CREATE_AGREEMENT: "/user/save-agreement",
  CREATE_AGREEMENT_HTML: "/user/save-agreement-html",

  // "/user/save-agreement",
  GET_AGREEMENT_DETAIL: "booking/agreementBookingDetail",
  UPLOAD_ID_PROOF: "booking/upload-id-proof-in-booking",

  // update guest

  UPDATE_USER_AGREEMENT: "user/update-user-from-agreement",

  GET_USER_DETAIL: "/user/userDetail",

  // data migration

  UPLOAD_BOOKING: "/import-reports/import-bookings",
  UPLOAD_ROME_RATES: "/import-reports/import-rates",
  UPLOAD_OTA_RATES: "/import-reports/import-ota-rates",
  UPLOAD_CENTRAL_RATES: "/import-reports/import-central-rates",
  UPLOAD_MIN_STAY: "/import-reports/import-min-say",

  UPLOAD_CLOUDBEDS_CUSTOMERS: "/import-reports/import-cloudbeds-customers",
  UPLOAD_CLOUDBEDS_BOOKINGS: "/import-reports/import-cloudbeds-bookings",


  // distance
  POPULAR_PLACE: "popular-places",

  ADMINUSER: "adminusers",

  // Roles
  ROLES: "roles",
  ROLES_PERMISSION: "roles/get-permissions",
  ROLES_ASSIGN_PERMISSION: "roles/assign-permissions",

  //module
  MODULE: "modules",

  //Destination
  DESTINATIONS: "destinations",
  DESTINATIONS_FOR_SIGNUP: "destinations/create-destination-from-signup",

  //owners
  OWNERS_USERS: "owner-users",
  GET_HOTELS_LIST_FOR_SELECT: "hotels/hotel-list-for-select",
  GET_OWNER_ARRIVAL_REPORT: "owners/arrival-list",
  GET_OWNER_DEPARTURE_REPORT: "owners/departure-list",


  CREATE_PROPERTY: "content/createUpdateProperty",

  GET_ROOM_DATA: "rooms/get-room-list-for-calender",

  SHIFTS: "shifts",


  //chat details

  GET_CHAT_USER_LIST: "chat/get-users-list",
  GET_CHAT_USER_DATA: "chat/get-messages",
  GET_EMAIL_CHAT_USER_DATA: "chat/get-email-messages",
  SEND_MESSAGE: "chat/send-message",
  GET_PENDING_AGREEMENT_BOOKING: "chat/get-pending-agreement-signed-bookings-of-user",


  //Attraction details
   ATTRACTION_RESOURCE_ROUTE: "attractions",


   //Miscellaneous Charges
    MISCELLANEOUS_CHARGES_RESOURCE_ROUTE: "miscellaneous-charges",

    //Recurring Charges
    GET_RECURRING_CHARGE:"recurring-charges",

    //Operating Expenses
    OPERATING_EXPENSES_RESOURCE_ROUTE: "operating-expenses",

    //Profit Loss Report
    PROFIT_LOSS_REPORT: "reports/profit-and-loss-report",

    //Occupancy Prediction 
    OCCUPANCY_PREDICTION: "reports/get-predicted-occ",
    ADD_OCCUPANCY_PREDICTION: "crons//add-predicted-occ-from-ai",

};
