import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createAttraction = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ATTRACTION_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateAttraction = async (data: object, attractionId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ATTRACTION_RESOURCE_ROUTE + `/${attractionId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomCategoryList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.ATTRACTION_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAttrcation = async (attractionId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ATTRACTION_RESOURCE_ROUTE + `/${attractionId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAttractionDetail = async (attractionId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ATTRACTION_RESOURCE_ROUTE + `/${attractionId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};


