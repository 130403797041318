import {createSlice} from '@reduxjs/toolkit';


//here just create for operatingExpenses


const initialState = {
     operatingExpensesList: [],
     operatingExpensesDetail: null

    };

const operatingExpensesSlice = createSlice({
    name: 'operatingExpensesSlice',
    initialState: initialState,
    reducers: {
        operatingExpensesListSucess: (state, action) => {
            state.operatingExpensesList = action.payload;
        },
        operatingExpensesListFailed: (state, action) => {
            state.operatingExpensesList = [];
        },
        operatingExpensesDetailSuccess: (state, action) => {
            state.operatingExpensesDetail = action.payload;
        },
        operatingExpensesDetailFailed: (state, action) => {
            state.operatingExpensesDetail = null;        
        },
    }
});

export const {
    operatingExpensesListSucess,
    operatingExpensesListFailed,
    operatingExpensesDetailSuccess,
    operatingExpensesDetailFailed
} = operatingExpensesSlice.actions;

export default operatingExpensesSlice.reducer;
