import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createOperatingExpenses = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OPERATING_EXPENSES_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateOperatingExpenses = async (data: object, operatingExpensesId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OPERATING_EXPENSES_RESOURCE_ROUTE + `/${operatingExpensesId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOperatingExpensesList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.OPERATING_EXPENSES_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteOperatingExpenses = async (operatingExpensesId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OPERATING_EXPENSES_RESOURCE_ROUTE + `/${operatingExpensesId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOperatingExpensesDetail = async (operatingExpensesId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OPERATING_EXPENSES_RESOURCE_ROUTE + `/${operatingExpensesId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};


